<template>
  <div class="scroll-radio">
    <div class="scroll-radio__title">{{ title }}</div>
    <div class="scroll-radio__list">
      <ScrollRadioItem
        v-model="value"
        v-for="(item, index) in items"
        :key="item"
        :item="item"
        :unit="unit"
        :name="name"
        :formatted="formatted"
      />
    </div>
  </div>
</template>

<script>
import ScrollRadioItem from './ScrollRadioItem.vue';
export default {
  name: 'ScrollRadio',
  components: { ScrollRadioItem },

  props: {
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    unit: {
      type: String,
      default: '',
    },
    selectedValue: {
      type: String,
      default: undefined,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    formatted: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      value: '110000',
    };
  },

  watch: {
    value(v) {
      this.$emit('update:modelValue', v);
    },
  },
};
</script>
