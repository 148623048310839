<template>
  <BaseModal
    :modal-id="modalId"
    :title="title"
    :css-classes="cssClasses"
    :showFooter="false"
  >
    <div class="mb-2 mt-2 mb-md-4">
      <ScrollRadio
        v-model="selectedOption"
        :name="name"
        :unit="unit"
        :items="options"
        :formatted="formatted"
      />
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from '../modals/BaseModal.vue';
import ScrollRadio from '../general/ScrollRadio.vue';

export default {
  name: 'SingleRangeSelectionModal',

  emits: ['selected'],

  components: {
    ScrollRadio,
    BaseModal,
  },

  props: {
    options: {
      type: Array,
      required: true,
    },

    title: {
      type: String,
      default: '',
    },

    modalId: {
      type: String,
      default: () => `modal-id-${Math.random().toString(36).slice(7)}`,
    },

    name: {
      type: String,
      default: 'radio',
    },

    unit: {
      type: String,
      default: '',
    },

    cssClasses: {
      type: Array,
      default: () => [],
    },

    optionWrapperStyle: {
      type: String,
      default: 'col-12 col-sm-4 col-md-4 col-lg-3 mb-1',
    },

    formatted: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      selectedOption: undefined,
    };
  },

  watch: {
    selectedOption(value) {
      this.$emit('selected', { value });
      this.$store.dispatch('ModalStore/closeModal');
    },
  },

  computed: {
    items() {
      return this.options.map((v) => v.value);
    },
  },
};
</script>
