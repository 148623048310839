<template>
  <img :src="`/images/${path}.svg`" :alt="alt" :width="width" :height="height">
</template>

<script>
export default {
  name: 'SvgIcon',
  props: {
    path: {
      type: String,
      required: true,
    },

    width: {
      type: Number,
      default: 18,
    },

    height: {
      type: Number,
      default: 18,
    },

    styleCss: {
      type: String,
      default: '',
    },

    alt: {
      type: String,
      default: "",
    },
  },
}
</script>
