window.addEventListener('DOMContentLoaded', function () {
    let goTopButton = document.getElementById('go-top');
    window.addEventListener('scroll', function () {
        let scrollPosition = window.scrollY;
        let pageHeight = (window.innerHeight > 0) ? window.innerHeight : screen.height;

        if (scrollPosition && scrollPosition >= (pageHeight / 2)) {
            goTopButton.classList.add('go-top--active');
        } else {
            goTopButton.classList.remove('go-top--active');
        }
    });
});