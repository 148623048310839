document.addEventListener("DOMContentLoaded", function(event) {
  const sortingTogglers = document.querySelectorAll('.sorting__toggler');
  const sortingChecks= document.querySelectorAll('.sorting__check');

  // Listener sort toggle
  sortingTogglers.forEach(function (sortingToggler) {
    sortingToggler.addEventListener('click', function() {
      const sortingDropdown = sortingToggler.nextElementSibling;
      sortingToggler.classList.toggle('sorting__toggler--active');
      sortingDropdown.classList.toggle('sorting__dropdown--active');
    });
  });

  // Listener sorts
  sortingChecks.forEach(function (sortingCheck) {
    sortingCheck.addEventListener('change', function(e) {
      sortingCheck.parentElement.classList.remove('sorting__dropdown--active');
      setDefaultSelected();
      sortingCheck.parentElement.previousElementSibling.classList.remove('sorting__toggler--active')
      if(window.filter) window.filter.search({ sort: sortingCheck.value })
    });
  });

  setDefaultSelected();
});

// Set default value to sorting__select
function setDefaultSelected() {
  const selectedAll = document.querySelectorAll('.sorting__check:checked');
  selectedAll.forEach(function (selected) {
    const selectedValue = selected.nextElementSibling.innerHTML;
    const sortingToggler = selected.parentElement.previousElementSibling.getElementsByClassName('sorting__selected')[0].parentElement;
    sortingToggler.classList.remove('sorting__toggler--active')
    selected.parentElement.previousElementSibling.getElementsByClassName('sorting__selected')[0].innerHTML = selectedValue;
  })
}
