<template>
  <li class="checklist__item checklist__item--checked">
    <span class="link--icon">
      {{ item }}
      <i
        class="icon icon--md icon-dismiss ms-1"
        style="vertical-align: text-bottom"
        @click="$emit('remove')"
        >x</i
      >
    </span>
  </li>
</template>

<script>
export default {
  name: 'CarEquipmentListItem',

  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>
