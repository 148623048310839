<template>
  <div class="col-xl-4 col-lg-6 pt-lg-2 pt-2 pb-lg-3 pb-2" :class="{ 'bg-info-lighter': !this.isSelectedOption }">
    <label :for="id" class="form-label" :class="{ 'form-label-disabled': isDisabled }">
      {{ label }}
      <span v-if="isRequired" class="form-label__required"></span>
    </label>
    <div @click="openModal(modalId)" class="form-control-wrap form-control-wrap--lg" :class="{ 'form-control-wrap--clickable': !isDisabled }">
      <input type="text" class="form-control form-control-lg" readonly :id="id" placeholder="Vyberte" :value="value" :disabled="isDisabled">
      <label class="icon icon--lg icon-caret-down bg-gray-700" :for="id"></label>
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash'

export default {
  name: 'FormSelect',

  emits: ["reset"],

  props: {
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    unit: {
      type: String,
      default: ""
    },
    modalId: {
      type: String,
      required: true
    },
    selectedOption: {
      type: Object,
      default: undefined
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    value() {
      return this.isSelectedOption ? `${this.selectedOption.value} ${this.unit}` : ""
    },

    isSelectedOption() {
      return !isEmpty(this.selectedOption)
    }
  },

  methods: {
    openModal(modalId) {
      if(!this.isDisabled) {
        this.$store.dispatch('ModalStore/openModal', {
          id: modalId
        })
      }
    },

    reset() {
      this.$emit('reset')
    }
  }
}
</script>