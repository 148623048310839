import { isFunction } from 'lodash'
import { ref }  from 'vue'

const ModalStore = {
  namespaced: true,
  state: {
    openedModalId: ref(undefined),
    cssClasses: [],
    params: {},
    scrollbarWidth: 0,
  },

  mutations: {
    openModal(state, params) {
      console.log(params.id)
      state.openedModalId = params.id
      state.params = params
    },

    closeModal(state) {
      state.openedModalId = undefined
      state.params = {}
    },

    shakeModal(state) {
      state.cssClasses = ['shake']
      setTimeout(() => {
        state.cssClasses = []
      }, 1000)
    },
  },

  actions: {
    openModal({ state, commit, dispatch }, params) {
      if (state.openedModalId !== params.id) {
        dispatch('setScrollBar')
        commit('openModal', params)
      }
    },

    closeModal({ commit, state, dispatch }) {
      if (state.openedModalId) {
        dispatch('removeScrollBar')
        if (isFunction(state.params.closeCallback)) {
          state.params.closeCallback()
        }
        commit('closeModal')
      }
    },

    shakeModal({ commit }) {
      commit('shakeModal')
    },

    setScrollBar() {
      // const scrollDiv = document.createElement('div')
      // scrollDiv.className = 'modal-scrollbar-measure'
      // document.body.append(scrollDiv)
      // const scrollbarWidth = scrollDiv.getBoundingClientRect().width - scrollDiv.clientWidth
      // scrollDiv.remove()
      //
      // if (scrollbarWidth > 0) {
      //   document.body.style.paddingRight = `${scrollbarWidth}px`
      // }

      let backdrop = document.createElement('div');
      backdrop.classList.add('modal-backdrop', 'fade', 'show')
      document.body.appendChild(backdrop);

      document.body.classList.add('modal-open')
      //document.body.style.overflow = 'hidden'
      //document.body.style.paddingRight = '0px'
    },

    removeScrollBar() {
      let backdrop = document.querySelector('.modal-backdrop');
      backdrop.remove()
      document.body.style.paddingRight = ''
      document.body.classList.remove('modal-open')
    },
  },

  getters: {
    params: (state) => {
      return state.params
    },
  },
}

export default ModalStore
