import { isNumber, isEmpty, isNaN } from 'lodash';

class HelperService {
  isBlank = (value) => {
    return (isEmpty(value) && !isNumber(value)) || isNaN(value);
  };

  objectMapper = (array) => {
    return array.map((v, i) => {
      return { id: i + 1, value: v.toString() };
    });
  };
}

export default new HelperService();
