document.addEventListener("DOMContentLoaded", function(event) {
    let radioContentElements = document.getElementsByClassName("content-radio__radio");

    for (let i = 0; i < radioContentElements.length; i++) {
        if (radioContentElements[i].checked) {
            radioContentElements[i].closest(".content-radio").classList.add("content-radio--active");
        } else {
            radioContentElements[i].closest(".content-radio").classList.remove("content-radio--active");
        }

        radioContentElements[i].addEventListener('change', (event) => {
            for (let i = 0; i < radioContentElements.length; i++) {
                if (radioContentElements[i] === event.currentTarget) {
                    radioContentElements[i].closest(".content-radio").classList.add("content-radio--active");
                } else {
                    radioContentElements[i].closest(".content-radio").classList.remove("content-radio--active");
                }
            }
        })
    }

})

