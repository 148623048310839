<template>
  <div class="col-lg mt-lg-3 mt-2">
    <label :for="id" class="form-label">
      {{ label }}
      <button @click="reset" v-show="showLabel" class="form-label__reset js-reset-input text-danger">
        <i class="icon icon--sm icon-dismiss-circle bg-danger"></i> reset
      </button>
    </label>
    <div @click="openModal(modalId)" class="form-control-wrap form-control-wrap--lg form-control-wrap--clickable">
      <input type="text" class="form-control form-control-lg has-icon" :class="{ 'is-filled': showLabel }" readonly :id="id" :value="selectedOptionsLabel" placeholder="Vyberte">
      <label class="icon icon--lg icon-add-circle bg-gray-700" :for="id"></label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilterRangeInput',
  emits: ["reset"],

  props: {
    id: {
      type: String,
      required: true
    },

    unit: {
      type: String,
      default: ""
    },

    modalId: {
      type: String,
      required: true
    },

    label: {
      type: String,
      required: true
    },

    selectedOptions: {
      type: Object,
      default: undefined
    },
  },

  computed: {
    selectedFrom() {
      return this.selectedOptions?.from
    },
    selectedTo() {
      return this.selectedOptions?.to
    },
    selectedOptionsLabel() {
      return this.showLabel ? `${this.selectedFrom} - ${this.selectedTo}` : undefined
    },
    showLabel() {
      return this.selectedFrom >= 0 && this.selectedTo >= 0
    }
  },
  methods: {
    openModal(modalId) {
      this.$store.dispatch('ModalStore/openModal', {
        id: modalId
      })
    },

    reset() {
      this.$emit('reset')
    },
  }
}
</script>