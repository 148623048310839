document.addEventListener("DOMContentLoaded", function(event) { 
  var userLeavingForm = document.getElementById('user-leaving-form');
  var anotherReasonControl = document.getElementById('another-reason');
  var reasonMessage = document.getElementById('reason-message');
  var userLeavingSubmit = document.getElementById('user-leaving-submit');

  if (typeof userLeavingForm !== 'undefined' && userLeavingForm !== null) {

    //Check another reason for collapse reason message
    anotherReasonControl.addEventListener('change', function(e) {
      toggleMessageArea(anotherReasonControl, reasonMessage)
    });

    //Check if form has reason
    userLeavingForm.addEventListener('change', function(e) {
      toggleSubmit(userLeavingForm, userLeavingSubmit, reasonMessage)
    });
    reasonMessage.addEventListener('keyup', function(e) {
      toggleSubmit(userLeavingForm, userLeavingSubmit, reasonMessage)
    });

    //on submit form (show success message)
    userLeavingForm.onsubmit = function(e){onSubmitUserLeavingForm(e)};

    //Set default collapse reason message
    toggleMessageArea(anotherReasonControl, reasonMessage);
    //Set default submit button disabled/enabled
    toggleSubmit(userLeavingForm, userLeavingSubmit, reasonMessage)

  }
});

function toggleMessageArea(anotherReasonControl, reasonMessage) {
  if (anotherReasonControl.checked) {
        reasonMessage.classList.remove('d-none');
        reasonMessage.required = true;
        reasonMessage.focus();
    } else {
        reasonMessage.classList.add('d-none');
    }
}

function toggleSubmit(userLeavingForm, userLeavingSubmit, reasonMessage) {
  var reasonOne = document.getElementById('reason-1');
  var reasonTwo = document.getElementById('reason-2');
  var reasonThree = document.getElementById('reason-3');
  var anotherReason = document.getElementById('another-reason');
  if(reasonOne.checked || reasonTwo.checked || reasonThree.checked || (anotherReason.checked && reasonMessage.value != '')) {
    userLeavingSubmit.disabled = false;
  } else {
    userLeavingSubmit.disabled = true;
  }
}

function onSubmitUserLeavingForm(e) {
  e.preventDefault();
  document.getElementById('user-leaving-form-actions').classList.add('d-none');
  document.getElementById('user-leaving-form-success').classList.remove('d-none');
}