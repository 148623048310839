import { createApp } from 'vue';
import Filter from '../components/filter/Filter.vue'

import store from '../vuex'
import { Tab } from 'bootstrap';

export const PrivateAdvertisementIndexView = {

  init() {
    const filter = document.getElementById('filter-wrapper')
    const searchParams = JSON.parse(filter.dataset.searchParams)

    let ssrInit = true
    let personalFilter = undefined
    let utilityFilter = undefined
    let modelType = undefined
    const buildBaseUrl = (modelType) => { return `/soukroma-inzerce/${modelType === 'utility' ? 'uzitkova-auta' : 'osobni-auta'}` }

    document.addEventListener('show.bs.tab', function(e) {
      if(e.target.id === 'nav-personal-tab') {
        modelType = 'personal'
        if(personalFilter) {
          personalFilter.initialize()
        } else {
          personalFilter = createApp(Filter, { redirect: false, baseUrl: buildBaseUrl(modelType), modelType: modelType, advertisementType: 'private', searchParams, saveLastSearchUrl: true })
            .use(store)
            .mount('#filter-wrapper-personal')
          if(ssrInit) {
            personalFilter.loadFilterOptions()
          } else {
            personalFilter.initialize()
          }
        }
        window.filter = personalFilter
      } else if(e.target.id === 'nav-utility-tab') {
        modelType = 'utility'
        if(utilityFilter) {
          utilityFilter.initialize()
        } else {
          utilityFilter = createApp(Filter, { redirect: false, baseUrl: buildBaseUrl(modelType), modelType: modelType, advertisementType: 'private', searchParams, saveLastSearchUrl: true })
            .use(store)
            .mount('#filter-wrapper-utility')
          if(ssrInit) {
            utilityFilter.loadFilterOptions()
          } else {
            utilityFilter.initialize()
          }
        }
        window.filter = utilityFilter
      }
    })

    if(searchParams.model_type === 'personal') {
      const tab = new Tab(document.querySelector('#nav-personal-tab'))
      tab.show()
    } else if(searchParams.model_type === 'utility') {
      const tab = new Tab(document.querySelector('#nav-utility-tab'))
      tab.show()
    }
    ssrInit = false
  }

}