import { Modal } from 'bootstrap';
import YouTubePlayer from 'youtube-player';

export const VideoModal = {
  init() {
    let player;
    const videoModalEl = document.querySelector('#videoModal');

    videoModalEl.addEventListener('show.bs.modal', (event) => {
      const button = event.relatedTarget;
      const videoId = button.dataset.video;
      player = YouTubePlayer('video', {
        videoId,
      });
      player.playVideo();
      player.on('stateChange', (stateChangeEvent) => {
        if (stateChangeEvent.data === 0) {
          Modal.getInstance(videoModalEl).hide();
        }
      });
    });

    videoModalEl.addEventListener('hide.bs.modal', () => {
      resetView(player);
    });
  },
};

const resetView = function (player) {
  if (!player) {
    console.log('Player could not be found.');
  } else {
    player.stopVideo();
    player.destroy();
  }
};
