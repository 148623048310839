<template>
  <div class="row mt-2 pt-2 pb-2" :class="{ 'bg-info-lighter': !this.isValid }">
    <div class="col-12">
      <label class="form-label">Výbava</label>
      <div class="form-label__info-text">
        Výbava je je součástí vyhledávacího filtru.
      </div>
    </div>

    <div
      v-for="equipment in carEquipments"
      :key="equipment.id"
      class="col-xl-4 col-lg-6 pb-1"
    >
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          v-model="selectedEquipments"
          :value="equipment"
          :id="`equipment-${equipment.id}`"
        />
        <label class="form-check-label" :for="`equipment-${equipment.id}`">
          {{ equipment.name }}
        </label>
      </div>
    </div>

    <div class="form-control-wrap form-control-wrap--lg mt-3">
      <div class="input-group mb-3">
        <input
          v-model="newItem"
          @keyup.enter="addItem"
          type="text"
          maxlength="30"
          class="form-control form-control-lg"
          placeholder="Přidejte další výbavu"
          aria-label="Přidejte další výbavu"
          aria-describedby="car-equipment-add-btn"
        />
        <button
          @click="addItem"
          class="btn btn-primary"
          type="button"
          id="car-equipment-add-btn"
        >
          Přidat
        </button>
      </div>
      <div class="form-counter" data-counter="subtitle">
        {{ `${newItemCounter}/30` }}
      </div>
    </div>
    <div class="col-12">
      <ul class="checklist pt-1">
        <CarEquipmentListItem
          v-for="(item, index) in customEquipments"
          :key="item"
          :item="item"
          @remove="removeItem(index)"
        />
      </ul>
    </div>
  </div>
</template>

<script>
import CarEquipmentListItem from '../advertisement/CarEquipmentListItem.vue';

export default {
  name: 'CarEquipmentList',

  components: {
    CarEquipmentListItem,
  },

  props: {
    carEquipments: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      newItem: undefined,
    };
  },

  computed: {
    customEquipments: {
      get() {
        return this.$store.state.AdvertisementStore.advertisement.customEquipments;
      },
      set(value) {
        this.$store.commit('AdvertisementStore/customEquipments', value);
      },
    },
    selectedEquipments: {
      get() {
        return this.$store.state.AdvertisementStore.advertisement.carEquipments;
      },
      set(value) {
        this.$store.commit('AdvertisementStore/carEquipments', value);
      },
    },
    isValid() {
      return (
        this.selectedEquipments.length > 0 || this.customEquipments.length > 0
      );
    },
    newItemCounter() {
      return this.newItem ? this.newItem.length : 0;
    },
  },

  methods: {
    addItem() {
      if (this.newItem) {
        this.customEquipments.push(this.newItem);
        this.newItem = undefined;
      }
    },

    removeItem(index) {
      this.customEquipments.splice(index, 1);
    },
  },
};
</script>
