import Sortable from 'sortablejs';

document.addEventListener("DOMContentLoaded", function(event) {
    let imageCheckboxElements = document.getElementsByClassName("gallery-list");

    for (let i = 0; i < imageCheckboxElements.length; i++) {
        let sortable = Sortable.create(imageCheckboxElements[i], {
            handle: '.gallery-list__handle',
            animation: 150,
            ghostClass: "gallery-list__ghost",
            dragClass: "gallery-list__drag",
        });
    }

})