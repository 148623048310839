<template>
  <section class="section" id="step-3">
    <h3 class="mt-5">Kontaktní údaje</h3>
    <div class="row mt-3">
      <FormInput
        v-model="authorName"
        label="Jméno nebo firma"
        input-type="text"
        :is-required="true"
        :is-read-only="true"
        :is-disabled="true"
        sub-label="Bude zveřejněno"
        css-style="col-xl-6 pt-lg-2 pt-2 pb-lg-3 pb-2"
      />
      <FormInput
        v-model="contactEmail"
        label="Kontaktní e-mail"
        input-type="text"
        :is-required="true"
        :is-read-only="true"
        :is-disabled="true"
        sub-label="Jen k přeposlání písemných nabídek"
        css-style="col-xl-6 pt-lg-2 pt-2 pb-lg-3 pb-2"
      />
      <FormInput
        v-model="contactPhone"
        label="Kontaktní telefon"
        input-type="text"
        input-mode="numeric"
        masked-type="phone"
        max-length="11"
        prepend="+420"
        :is-required="true"
        sub-label="Bude zveřejněn"
        css-style="col-xl-6 pt-lg-2 pt-2 pb-lg-3 pb-2"
      />
      <FormSelectSimple
        id="region"
        v-model="region"
        :model-value="region"
        :options="regions"
        label="Region, kde je možno auto vidět"
        :is-required="true"
        sub-label="Bude zveřejněn"
        css-style="col-xl-6 pt-lg-2 pt-2 pb-lg-3 pb-2"
      />
      <FormTextArea
        v-model="authorDescription"
        label="Další informace o prodávajícím"
        :show-length-counter="true"
        :max-length="1000"
        :rows-count="5"
        placeholder="Uveďte například kdy vám mohou zájemci volat, kde je možné auto vidět a další upřisnění ohledně prodeje..."
        css-style="col-12 pt-lg-2 pt-2 pb-lg-3 pb-2"
        sub-label="Praktické informace k prodeji."
      />
    </div>
  </section>
</template>

<script>
import ApiService from '../../../services/apiService.js';
import HelperService from '../../../services/helperService.js';
import FormInput from '../../FormInput.vue';
import FormTextArea from '../../FormTextArea.vue';

export default {
  name: 'ContactInfoStep',

  components: {
    FormInput,
    FormTextArea,
  },

  data() {
    return {
      currentUser: undefined,
      regions: HelperService.objectMapper([
        'Hlavní město Praha',
        'Středočeský kraj',
        'Jihočeský kraj',
        'Jihomoravský kraj',
        'Karlovarský kraj',
        'Královehradecký kraj',
        'Liberecký kraj',
        'Moravskoslezský kraj',
        'Olomoucký kraj',
        'Pardubický kraj',
        'Plzeňský kraj',
        'Ústecký kraj',
        'Vysočina',
        'Zlínský kraj',
      ]),
    };
  },

  computed: {
    authorName: {
      get() {
        return this.$store.state.AdvertisementStore.advertisement.authorName;
      },
      set(value) {
        this.$store.commit('AdvertisementStore/authorName', value);
      },
    },

    contactEmail: {
      get() {
        return this.$store.state.AdvertisementStore.advertisement.contactEmail;
      },
      set(value) {
        this.$store.commit('AdvertisementStore/contactEmail', value);
      },
    },

    contactPhone: {
      get() {
        return this.$store.state.AdvertisementStore.advertisement.contactPhone;
      },
      set(value) {
        this.$store.commit('AdvertisementStore/contactPhone', value);
      },
    },

    region: {
      get() {
        return this.$store.state.AdvertisementStore.advertisement.region;
      },
      set(value) {
        this.$store.commit('AdvertisementStore/region', value);
      },
    },

    authorDescription: {
      get() {
        return this.$store.state.AdvertisementStore.advertisement
          .authorDescription;
      },
      set(value) {
        this.$store.commit('AdvertisementStore/authorDescription', value);
      },
    },
  },

  async mounted() {
    const { data } = await ApiService.get('users/current');
    this.currentUser = data.currentUser;
    this.authorName = data.currentUser.name;
    this.contactEmail = data.currentUser.contactEmail;
    this.contactPhone = data.currentUser.contactPhone;
    this.region = data.currentUser.region;
    this.authorDescription = data.currentUser.authorDescription;
  },
};
</script>
