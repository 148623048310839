<template>
  <BaseModal :modal-id="modalId" :showHeader="false" :showFooter="false" :css-classes="['modal-xs']" :title="params.title">
    <button @click="closeModal" type="button" class="btn-close" aria-label="Close">
      <i class="icon icon--lg icon-dismiss d-none d-md-block d-lg-block d-xl-block d-xxl-block"></i>
      <i class="icon icon--md icon-dismiss d-md-none"></i>
    </button>
    <div class="mb-2 mt-2 mb-md-4 mt-md-4">
      <div class="text-center pt-3 pt-md-6">
        <i class="icon icon--lg icon-logo-check bg-success-dark icon--xlg mb-1"></i>
        <h3 class="h3 text-success-dark mb-1" id="newOfferSuccessLabel">{{ params.title }}</h3>
        <p>{{ params.msg }}</p>
      </div>
    </div>
    <div v-if="params.created && params.advertisementType != 'professional'">
      <hr>
      <div class="text-center my-3">
        <h4>Pro soukromé prodávající</h4>
        <a :href="params.advertisementUrl+'?stickers_for='+params.advertisementId" class="btn btn-lg btn-gray">Zaslat samolepky zdarma</a>
      </div>
      <hr>
      <div class="text-center my-3 mb-4">
        <h4>Pro autobazary / profesionální prodej</h4>
        <a href="/clanky/jak-funguje-web#profesionalni-prodej" class="btn btn-lg btn-gray">Import inzerátů a další vychytávky</a>
      </div>
    </div>
      <template v-if="!params.created || params.advertisementType == 'professional'" v-slot:footer>
        <div class="modal-footer">
          <a :href="params.advertisementUrl" class="btn btn-lg btn-gray flex-fill me-2 ps-1 pe-1">Ukázat inzerát</a>
          <a :href="params.advertisementsIndexUrl" class="btn btn-lg btn-outline-gray flex-fill ps-1 pe-1">Správa inzerátů</a>
        </div>
      </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../modals/BaseModal.vue";
export default {
  name: 'ModalAdvertisementCreated',

  components: {
    BaseModal
  },

  data() {
    return {
      modalId: 'modal-advertisement-created',
    }
  },

  computed: {
    params() {
      return this.$store.getters['ModalStore/params']
    }
  },
  methods: {
    closeModal() {
      this.$store.dispatch('ModalStore/closeModal')
    },
  },
}
</script>
