import { ref }  from 'vue'

const FilterStore = {
  namespaced: true,
  state: {
    brands: ref([]),
    selectedBrands: ref([]),
    models: ref([]),
    selectedModels: ref([]),
    bodies: ref([]),
    selectedBodies: ref([]),
    fuels: ref([]),
    selectedFuels: ref([]),
    selectedPriceRange: ref({
      from: undefined,
      to: undefined
    }),
    selectedDisplacement: ref({
      from: undefined,
      to: undefined
    }),
    selectedEnginePower: ref({
      from: undefined,
      to: undefined
    }),
    selectedRidesSince: ref({
      from: undefined,
      to: undefined
    }),
    selectedRidesSinceYear: ref({
      from: undefined,
      to: undefined
    }),
    selectedMileage: ref({
      from: undefined,
      to: undefined
    }),
    gearboxes: ref([]),
    selectedGearboxes: ref([]),
    wheelDrives: ref([]),
    selectedWheelDrives: ref([]),
    countries: ref(['Česká republika', 'Slovensko', 'Polsko']),
    selectedSeats: ref(undefined),
    selectedCountries: ref([]),
    carStates: ref(['Nové', 'Ojeté', 'Havarované']),
    selectedCarStates: ref([]),
  },

  mutations: {
    selectBrands(state, brands) {
      [...state.selectedBrands] = [...brands]
    },
    selectModels(state, models) {
      [...state.selectedModels] = [...models]
    },
    selectBodies(state, bodies) {
      [...state.selectedBodies] = [...bodies]
    },
    selectFuels(state, fuels) {
      [...state.selectedFuels] = [...fuels]
    },
    selectDisplacement(state, displacement) {
      state.selectedDisplacement.from = displacement.from
      state.selectedDisplacement.to = displacement.to
    },
    selectEnginePower(state, enginePower) {
      state.selectedEnginePower.from = enginePower.from
      state.selectedEnginePower.to = enginePower.to
    },
    selectMileage(state, mileage) {
      state.selectedMileage.from = mileage.from
      state.selectedMileage.to = mileage.to
    },
    selectRidesSinceYear(state, ridesSince) {
      state.selectedRidesSinceYear.from = ridesSince.from
      state.selectedRidesSinceYear.to = ridesSince.to
    },
    selectPriceRange(state, priceRange) {
      console.log(priceRange)
      state.selectedPriceRange.from = priceRange.from
      state.selectedPriceRange.to = priceRange.to
    },
    selectGearboxes(state, gearboxes) {
      [...state.selectedGearboxes] = [...gearboxes]
    },
    selectWheelDrives(state, wheelDrives) {
      [...state.selectedWheelDrives] = [...wheelDrives]
    },
    selectSeats(state, seats) {
      state.selectedSeats = seats
    },
    selectCountries(state, countries) {
      [...state.selectedCountries] = [...countries]
    },
    selectCarStates(state, carStates) {
      state.selectedCarStates = [...carStates]
    },

    resetBrands(state) {
      state.selectedBrands.splice(0)
    },
    resetModels(state) {
      state.selectedModels.splice(0)
    },
    resetBodies(state) {
      state.selectedBodies.splice(0)
    },
    resetFuels(state) {
      state.selectedFuels.splice(0)
    },
    resetPriceRange(state) {
      state.selectedPriceRange.from = undefined
      state.selectedPriceRange.to = undefined
    },
    resetDisplacement(state) {
      state.selectedDisplacement.from = undefined
      state.selectedDisplacement.to = undefined
    },
    resetEnginePower(state) {
      state.selectedEnginePower.from = undefined
      state.selectedEnginePower.to = undefined
    },
    resetMileage(state) {
      state.selectedMileage.from = undefined
      state.selectedMileage.to = undefined
    },
    resetRidesSince(state) {
      state.selectedRidesSinceYear.from = undefined
      state.selectedRidesSinceYear.to = undefined
    },
    resetGearboxes(state) {
      state.selectedGearboxes.splice(0)
    },
    resetWheelDrives(state) {
      state.selectedWheelDrives.splice(0)
    },
    resetSelectedSeats(state) {
      state.selectedSeats = undefined
    },
    resetCarState(state) {
      state.selectedCarStates = undefined
    },
  },

  actions: {
    syncBrands({ state }, brands) {
      state.brands.splice(0, state.brands.length);
      brands.forEach(v => state.brands.push(v));
    },
    syncModels({ state }, models) {
      state.models.splice(0, state.models.length);
      models.forEach(v => state.models.push(v));
    },
    syncBodies({ state }, models) {
      state.bodies.splice(0, state.bodies.length);
      models.forEach(v => state.bodies.push(v));
    },
    syncFuels({ state }, fuels) {
      state.fuels.splice(0, state.fuels.length);
      fuels.forEach(v => state.fuels.push(v));
    },
    syncGearboxes({ state }, gearboxes) {
      state.gearboxes.splice(0, state.gearboxes.length);
      gearboxes.forEach(v => state.gearboxes.push(v));
    },
    syncWheelDrives({ state }, wheelDrives) {
      state.wheelDrives.splice(0, state.wheelDrives.length);
      wheelDrives.forEach(v => state.wheelDrives.push(v));
    },
  },
}

export default FilterStore
