<template>
  <BaseModal :modal-id="modalId" :css-classes="['modal-xl']" title="Správa fotografií">
    <div class="mb-3 mt-3 d-flex">
      <div class="input-group mb-3">
        <label class="btn btn-lg btn-gray flex-fill flex-sm-grow-0">
          Nahrát fotografie <input type="file" class="form-control" id="inputGroupFile01" @change="handleFileUploads( $event )" :disabled="this.images.length >= 20" multiple hidden>
        </label>
      </div>
    </div>
    <div class="mb-3 mt-3">
      <div class="alert alert--info alert--lg text-gray-700 position-relative" role="alert">
        <div>
          <a class="alert__toggle text-decoration-none text-gray-1000 fw-normal collapsed" data-bs-toggle="collapse" href="#photosMoreInfo" role="button" aria-expanded="false" aria-controls="photosMoreInfo">
            <span class="alert__toggle-state-active">
              <span class="d-none d-sm-inline-block">méně</span> <i class="icon icon--md icon-caret-up bg-gray-1000 me-0"></i>
            </span>
            <span class="alert__toggle-state">
              <span class="d-none d-sm-inline-block">více</span> <i class="icon icon--md icon-caret-down bg-gray-1000 me-0"></i>
            </span>
          </a>
          <ul class="mb-0">
            <li>Můžete nahrát 1–20 fotografií o velikosti max. 5 MB.</li>
          </ul>
          <ul class="mb-0 collapse" id="photosMoreInfo" style="">
            <li>Ideální poměr stran 4 : 3, ideální rozlišení 1600x1200 pixelů.</li>
            <li>Větší fotografie budou zmenšeny, menší ponechány v původní velikosti.</li>
            <li>Náhledový obrázek se zobrazí už v seznamu inzerátů. Přesuňte na pozici náhledového obrázku nejvhodnější fotku.</li>
            <li>Pokud náhledový obrázek nemá poměr stran 4 : 3, vyzveme vás k jeho úpravě.</li>
          </ul>
        </div>
      </div>

      <div v-if="error" class="alert alert--error alert--lg my-3" role="alert">
        <i class="icon icon-dismiss-circle"></i>
        {{ error }}
      </div>
    </div>

    <h4 class="mb-3 mt-3">Uspěšně nahrané fotografii</h4>
    <div class="mb-3 mt-3" style="position: relative; min-height: 200px">
      <draggable class="gallery-list" id="gallery-items" :list="images" ghost-class="gallery-list__ghost" handle=".icon-move">
        <transition-group>
        <li v-for="( image, index ) in images" :key="image" class="gallery-list__item bg-gray-300 rounded-3" style="">
          <div class="gallery-list__image rounded-2" :style="`background-image: url(${image.url})`">
            <div v-if="index === 0" class="gallery-list__badges">
              <div class="badge badge-xs-small text-sm-uppercase ls-1">Náhledové foto</div>
<!--                <div class="badge badge-xs-small bg-primary text-sm-uppercase ls-1">Nutno oříznout</div>-->
            </div>
          </div>
          <div class="d-flex justify-content-between p-2 pt-1 gallery-list__action">
            <div class="d-flex">
              <span class="d-flex gallery-list__handle">
                <i class="icon icon--lg icon-move d-none d-md-block"></i>
                <i class="icon icon-move d-block d-md-none"></i>
              </span>
            </div>
            <div class="d-flex">
              <button v-if="false" type="button" class="me-2 me-ms-3 btn-clear d-flex">
                <i class="icon icon--lg icon-edit d-none d-md-block"></i>
                <i class="icon icon-edit d-block d-md-none"></i>
              </button>
              <button @click.prevent="removeImage(index)" type="button" class="btn-clear d-flex">
                <i class="icon icon--lg icon-delete d-none d-md-block"></i>
                <i class="icon icon-delete d-block d-md-none"></i>
              </button>
            </div>
          </div>
        </li>
        </transition-group>
      </draggable>

      <div v-if="isLoading" style="display: flex; justify-content: center; align-items: center; position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0,0,0,0.1)">
        <BaseSpinner/>
      </div>
    </div>
    <div class="w-100 mb-1 mt-1">
      <div class="progress-bar">
        <div class="progress-bar__line" :style="`width: ${uploadProgress}%`"></div>
      </div>
      <div class="text-gray-1000">
        Nahráno {{ this.images.length }} souborů z 20
      </div>
    </div>

    <template v-slot:ok-button>
      <button @click="save" type="button" class="btn btn-lg btn-gray flex-fill flex-sm-grow-0">Uložit</button>
    </template>
  </BaseModal>
</template>

<script>
import ApiService from "../../services/apiService.js";
import BaseModal from "../modals/BaseModal.vue";
import axios from "axios";
import BaseSpinner from "../general/BaseSpinner.vue";
import { VueDraggableNext } from 'vue-draggable-next'

export default {
  name: 'UploadImagesModal',
  components: {
    BaseSpinner,
    BaseModal,
    draggable: VueDraggableNext,
  },
  data() {
    return {
      isLoading: false,
      error: undefined,
      files: [],
      modalId: 'gearboxes-modal',
    }
  },

  watch: {
    async isOpened(value) {
      if(value) {
        const { data } = await ApiService.get('gearboxes')
        this.$store.dispatch('FilterStore/syncGearboxes', data.gearboxes)
      }
    }
  },

  computed: {
    isOpened() {
      return this.$store.state.ModalStore.openedModalId === this.modalId
    },
    images: {
      get() {
        return this.$store.state.AdvertisementStore.advertisement.images
      },
      set(value) {
        this.$store.commit('AdvertisementStore/images', value)
      }
    },
    uploadProgress() {
      return (this.images.length / 20) * 100
    }
  },

  methods: {
    handleFileUploads(event) {
      this.files = event.target.files;
      this.uploadImages()
    },

    async uploadImages() {
      this.isLoading = true
      this.error = undefined
      let url = `${document.body.dataset.apiUrl}/photos`
      const formData = new FormData(); // pass data as a form
      for (let x = 0; x < this.files.length; x++) {
        // append files as array to the form, feel free to change the array name
        formData.append("images[]", this.files[x]);
      }
      try {
        const response = await axios.post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        const { images } = response.data
        this.images = images
        this.files = []
      } catch (error) {
        this.error = error.response.data.msg
      }
      this.isLoading = false
    },

    removeImage(index) {
      this.$store.commit('AdvertisementStore/removeImage', index)
    },

    save() {
      this.$store.dispatch('ModalStore/closeModal')
    }
  },
}
</script>
