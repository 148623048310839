import { createApp } from 'vue';
import Filter from '../components/filter/Filter.vue'
import AdvertisementForm from '../components/advertisement/AdvertisementForm.vue'

import store from '../vuex'
import { Tab } from 'bootstrap';

export const AdvertisementIndexView = {

  init() {
    const filter = document.getElementById('filter-wrapper')
    const searchParams = JSON.parse(filter.dataset.searchParams)
    const niceUrls = JSON.parse(filter.dataset.niceUrls)
    const personalNiceUrl = niceUrls.filter(u => u.model_type === 'personal')
    const utilityNiceUrl = niceUrls.filter(u => u.model_type === 'utility')

    let ssrInit = true
    let personalFilter = undefined
    let utilityFilter = undefined
    let modelType = undefined
    const buildBaseUrl = (modelType) => { return `/inzerce/${modelType === 'utility' ? 'uzitkova-auta' : 'osobni-auta'}` }

    document.addEventListener('show.bs.tab', function(e) {
      if(e.target.id === 'nav-personal-tab') {
        modelType = 'personal'
        if(personalFilter) {
          personalFilter.initialize()
        } else {
          personalFilter = createApp(Filter, { redirect: false, baseUrl: buildBaseUrl(modelType), modelType: modelType, searchParams, niceUrls: personalNiceUrl, saveLastSearchUrl: true })
            .use(store)
            .mount('#filter-wrapper-personal')
          if(ssrInit) {
            personalFilter.loadFilterOptions()
          } else {
            personalFilter.initialize()
          }
        }
        window.filter = personalFilter
      } else if(e.target.id === 'nav-utility-tab') {
        modelType = 'utility'
        if(utilityFilter) {
          utilityFilter.initialize()
        } else {
          utilityFilter = createApp(Filter, { redirect: false, baseUrl: buildBaseUrl(modelType), modelType: modelType, searchParams, niceUrls: utilityNiceUrl, saveLastSearchUrl: true })
            .use(store)
            .mount('#filter-wrapper-utility')
          if(ssrInit) {
            utilityFilter.loadFilterOptions()
          } else {
            utilityFilter.initialize()
          }
        }
        window.filter = utilityFilter
      }
    })

    if(searchParams.model_type === 'personal') {
      const tab = new Tab(document.querySelector('#nav-personal-tab'))
      tab.show()
    } else if(searchParams.model_type === 'utility') {
      const tab = new Tab(document.querySelector('#nav-utility-tab'))
      tab.show()
    }
    ssrInit = false
  }

}

export const AdvertisementShowView = {

  init() {
    AdvertisementShowView.loadLastSearchUrl()
  },

  // Loads last search URL from local storage.
  loadLastSearchUrl() {
    const link = document.querySelector('.back-button')
    link.addEventListener('click', (e) => {
      e.preventDefault()
      if(document.referrer.includes('inzerce')) {
        window.history.back()
      } else {
        window.location.href = e.target.href
      }
    })
    // if(localStorage.lastSearchFilterUrl) {
    //   const link = document.querySelector('#back-to-index-link')
    //   link.href = localStorage.lastSearchFilterUrl
    // }
  }

}

export const AdvertisementNewView = {

  init() {
    createApp(AdvertisementForm, { action: 'create' })
      .use(store)
      .mount('#form-advertisement')
  }

}

export const AdvertisementEditView = {

  init() {
    const wrapper = document.getElementById('form-advertisement')
    createApp(AdvertisementForm, { advertisementId: wrapper.dataset.advertisementId, action: 'update' })
        .use(store)
        .mount('#form-advertisement')
  }

}