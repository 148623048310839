<template>
  <div class="row pt-2 pb-2" :class="{ 'bg-info-lighter': !this.isValid }">
    <div class="col-12">
      <label class="form-label">
        {{ label }}
        <span v-if="isRequired" class="form-label__required"></span>
      </label>
    </div>
    <div class="col-xl-4">
      <button @click="openModal(modalId)" class="btn btn-lg btn-outline-gray px-xl-2 px-xxl-5 w-xs-100 bg-white">Spravovat fotografie</button>
    </div>
    <div class="col-xl-8 d-flex align-items-center">
      <div>
        <p class="fw-bolder small text-gray-900 mb-0 d-flex mt-2 mt-xl-0">
          Počet nahraných fotografií
          <span class="badge badge--sm ms-1">{{ this.images.length }}</span>
        </p>
        <p class="small mb-0">Max. 15 fotografií o velikosti max. 5 MB. Nahrajte min. 1 fotografii.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash'

export default {
  name: 'FormFile',

  emits: ["reset"],

  props: {
    label: {
      type: String,
      required: true
    },
    modalId: {
      type: String,
      required: true
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    images: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    isValid() {
      return this.images.length > 0
    }
  },

  methods: {
    openModal(modalId) {
      if(!this.isDisabled) {
        this.$store.dispatch('ModalStore/openModal', {
          id: modalId
        })
      }
    },

    reset() {
      this.$emit('reset')
    }
  }
}
</script>