<template>
  <div :class="{ 'bg-info-lighter': !this.isValid, [this.cssStyle]: true }">
    <label :for="id" class="form-label"
      >{{ label }}<span v-show="isRequired" class="form-label__required"></span
    ></label>
    <div
      v-if="subLabel.length > 0"
      class="form-label__info-text"
      v-html="subLabel"
    ></div>
    <div class="form-control-wrap form-control-wrap--lg">
      <div class="input-group">
        <span v-if="prepend" class="input-group-text">{{ prepend }}</span>
        <input
          :id="id"
          :value="modelValue"
          :type="inputType"
          :inputmode="inputMode"
          :maxlength="maxLength"
          v-imask="mask"
          class="form-control form-control-lg"
          :placeholder="placeholder"
          :disabled="isDisabled"
          :readonly="isReadOnly"
          @input="$emit('update:modelValue', $event.target.value)"
          @complete="$emit('update:modelValue', $event.detail.unmaskedValue)"
        />
        <span v-if="append" class="input-group-text">{{ append }}</span>
      </div>
      <div
        v-if="showLengthCounter && maxLength > 0"
        class="form-counter"
        data-counter="subtitle"
      >
        {{ `${lengthCounter}/${maxLength}` }}
      </div>
    </div>
  </div>
</template>

<script>
import { IMaskDirective } from 'vue-imask';

export default {
  name: 'FormInput',
  directives: {
    imask: IMaskDirective,
  },

  props: {
    inputType: {
      type: String,
      default: 'Text',
    },

    inputMode: {
      type: String,
      default: 'text',
    },

    modelValue: {
      type: String,
      default: undefined,
    },

    id: {
      type: String,
      default: '',
    },

    label: {
      type: String,
      required: true,
    },

    subLabel: {
      type: String,
      default: '',
    },

    prepend: {
      type: String,
      default: false,
    },

    append: {
      type: String,
      default: '',
    },

    maxLength: {
      type: Number,
      default: undefined,
    },

    minLength: {
      type: Number,
      default: undefined,
    },

    showLengthCounter: {
      type: Boolean,
      default: false,
    },

    placeholder: {
      type: String,
      default: 'Uveďte',
    },

    isRequired: {
      type: Boolean,
      default: false,
    },

    cssStyle: {
      type: String,
      default: '',
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isReadOnly: {
      type: Boolean,
      default: false,
    },

    maskedType: {
      type: String,
      default: '',
    },

    pattern: {
      type: RegExp,
      default: undefined,
    },
  },

  computed: {
    lengthCounter() {
      return this.modelValue ? this.modelValue.length : 0;
    },

    isValid() {
      if (this.pattern) {
        return this.modelValue && this.pattern.test(this.modelValue);
      } else if (this.minLength) {
        return this.modelValue && this.modelValue.length >= this.minLength;
      } else {
        return this.modelValue && this.modelValue.length >= 2;
      }
    },

    mask() {
      if (this.maskedType !== '') {
        if (this.maskedType === 'number') {
          return {
            mask: Number,
            thousandsSeparator: ' ',
          };
        }
        if (this.maskedType === 'phone') {
          return {
            mask: '000 000 000',
          };
        }
        if (this.maskedType === 'vin') {
          return {
            mask: /^\w+$/,
            prepare: function (str) {
              return str.toUpperCase();
            },
          };
        }
        return false;
      }
      return false;
    },
  },
};
</script>
