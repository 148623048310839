window.addEventListener('DOMContentLoaded', function () {
    var scrollPosition = window.scrollY;
    var headerContainer = document.getElementsByClassName('header')[0];

    window.addEventListener('scroll', function () {
        scrollPosition = window.scrollY;

        if (scrollPosition >= 300) {
            headerContainer.classList.add('header--scrolled');
        } else {
            headerContainer.classList.remove('header--scrolled');
        }
    });
});