<template>
  <div :class="wrapperStyle">
    <div class="form-check">
      <input @input="check"
             type="radio"
             :checked="checked"
             :id="fieldId"
             v-bind="$attrs"
             class="form-check-input">
      <label class="form-check-label" :for="fieldId">
        {{ option.value }}
      </label>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  name: 'FormOption',
  emits: ["toggled"],
  props: {
    option: {
      type: Object,
      required: true,
    },
    fieldId: {
      type: String,
      default: () => `radio-${Math.random().toString(36).slice(7)}`,
    },
    selected: {
      type: Object,
      required: true,
    },
    wrapperStyle: {
      type: String,
      default: "col-12 col-sm-4 col-md-4 col-lg-3 mb-1"
    }
  },
  setup(props, context) {
    const checked = computed(() => props.selected && props.selected.value === props.option.value)
    const check = () => {
      context.emit("toggled", props.option);
    }

    return {
      checked,
      check,
    };
  }
}
</script>