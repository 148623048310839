<template>
  <BaseModal :modal-id="modalId" :css-classes="cssClasses" :title="title">
    <div class="mb-1 mt-2 mb-md-3 mt-md-4">
      <div class="row">
        <div class="col">
          <ScrollRadio v-model="selectedFrom"
                       title="Od"
                       :name="`${name}-from`"
                       :unit="unit"
                       :items="fromItems"
                       :formatted="formatted" />
        </div>
        <div class="col">
          <ScrollRadio v-model="selectedTo"
                       title="Do"
                       :name="`${name}-to`"
                       :unit="unit"
                       :items="toItems"
                       :formatted="formatted" />
        </div>
      </div>
      <div v-if="selectedTo || selectedFrom" class="mt-2 mb-2 mt-md-4 mb-md-4">
        Počet nalezených inzerátů {{ advertisementsCount }}
      </div>
    </div>

    <template v-slot:ok-button>
      <button @click="save" type="button" class="btn btn-lg btn-gray flex-fill flex-sm-grow-0" :disabled="isDisabled">Uložit</button>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "./BaseModal.vue";
import FilterCheckbox from "../filter/FilterCheckbox.vue"
import ScrollRadio from "../general/ScrollRadio.vue"
import ApiService from "../../services/apiService"

export default {
  name: 'RangeSelectionModal',

  emits: ["selected"],

  components: {
    BaseModal,
    FilterCheckbox,
    ScrollRadio
  },

  props: {
    options: {
      type: Array,
      required: true,
    },
    savedRange: {
      type: Object,
    },
    urlParams: {
      type: String,
      required: true,
    },
    // Name of query parameter when checking count from backend.
    queryName: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    modalId: {
      type: String,
      default: () => `modal-id-${Math.random().toString(36).slice(7)}`,
    },
    name: {
      type: String,
      default: "radio",
    },
    unit: {
      type: String,
      default: "",
    },
    cssClasses: {
      type: Array,
      default: () => [],
    },
    formatted: {
      type: Boolean,
      default: true,
    },
    reversedOptions: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectedFrom: undefined,
      selectedTo: undefined,
      advertisementsCount: 0,
    }
  },

  computed: {
    isDisabled() {
      return this.advertisementsCount <= 0
    },
    fromItems() {
      return this.options.map((v) => {
        return {
          value: v.value,
          isSelected: v.value === this.selectedFrom,
          isDisabled: Number.parseInt(v.value) >= Number.parseInt(this.selectedTo)
        }
      })
    },
    toItems() {
      return this.options.map((v) => {
        return {
          value: v.value,
          isSelected: v.value === this.selectedTo,
          isDisabled: Number.parseInt(v.value) <= Number.parseInt(this.selectedFrom)
        }
      })
    },
    openModalId() {
      return this.$store.state.ModalStore.openedModalId
    },
  },

  watch: {
    selectedFrom(v) {
      this.checkAdvertisementsCount()
    },
    selectedTo(v) {
      this.checkAdvertisementsCount()
    },
    openModalId(value) {
      if(value === this.modalId && this.savedRange) {
        this.selectedFrom = this.savedRange.from
        this.selectedTo = this.savedRange.to
      }
    },
  },

  methods: {
    async checkAdvertisementsCount() {
      let params = new URLSearchParams(this.urlParams)
      const range = this.searchRange()
      params.append(this.queryName, `${range.from}-${range.to}`)
      const { data } = await ApiService.get(`advertisements/check?${params.toString()}`)
      this.advertisementsCount = data.count
    },
    searchRange() {
      let from = ''
      let to = ''
      if(this.reversedOptions) {
        from = this.selectedFrom ? this.selectedFrom : Number.parseInt(this.options[this.options.length - 1].value)
        to = this.selectedTo ? this.selectedTo : Number.parseInt(this.options[0].value)
      } else {
        from = this.selectedFrom ? this.selectedFrom : Number.parseInt(this.options[0].value)
        to = this.selectedTo ? this.selectedTo : Number.parseInt(this.options[this.options.length - 1].value)
      }
      return {
        from,
        to,
      }
    },
    save() {
      const range = this.searchRange()
      this.$emit('selected', { from: range.from, to: range.to })
      this.$store.dispatch('ModalStore/closeModal')
    }
  },
}
</script>