import { createStore } from 'vuex'
import ModalStore from './stores/modalStore'
import FilterStore from './stores/filterStore'
import AdvertisementStore from './stores/AdvertisementStore'

const store = createStore({
  modules: {
    ModalStore,
    FilterStore,
    AdvertisementStore,
  }
})

export default store
