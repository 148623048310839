<template>
  <div class="scroll-radio__item">
    <input
      :value="item.value"
      @change="$emit('update:modelValue', $event.target.value)"
      :checked="item.isSelected"
      class="scroll-radio__input"
      type="radio"
      :name="name"
      :id="fieldId"
      :disabled="item.isDisabled"
    />
    <label class="scroll-radio__label" :for="fieldId">
      {{ label }} {{ unit }}
    </label>
  </div>
</template>

<script>
export default {
  name: 'ScrollRadioItem',
  props: {
    fieldId: {
      type: String,
      default: () => `radio-scroll-${Math.random().toString(36).slice(7)}`,
    },
    name: {
      type: String,
      default: () => `${this.fieldId}-${Math.random().toString(36).slice(7)}`,
    },
    item: {
      type: Object,
      default: undefined,
    },
    unit: {
      type: String,
      default: '',
    },
    selectedValue: {
      type: String,
      default: undefined,
    },
    formatted: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    isSelected() {
      return this.item.value === this.selectedValue;
    },
    label() {
      return this.formatted
        ? this.item.value.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
        : this.item.value;
    },
  },
};
</script>
