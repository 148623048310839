<template>
  <div class="sorting mb-5" role="group">
    <div class="sorting__dropdown d-block">
      <span v-for="(stepperItem, index) in steps" :key="index" :class="itemClass(index)">
        <input type="radio" class="sorting__check" name="sorting-car" :id="`sorting-car-${index}`" autocomplete="off" :checked="index === currentStep">
        <label @click.prevent="updateCurrentStep(index)" class="sorting__choice" :for="`sorting-car-${index}`">{{ stepperItem }}</label>
      </span>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'

export default {
  name: 'Stepper',

  props: {
    currentStep: {
      type: Number,
      default: 0,
    },

    steps: {
      type: Array,
      required: true,
    },
  },

  setup(props, context) {
    const activeSteps = computed(() => [...Array.from({ length: props.currentStep + 1 }).keys()])

    const itemClass = (index) => [
      'stepper__item',
      'col',
      { 'stepper__item--current': index === activeSteps.value[index] },
    ]

    const updateCurrentStep = (index) => {
      context.emit('updateCurrentStep', index)
    }

    return {
      itemClass,
      updateCurrentStep
    }
  },
}
</script>
