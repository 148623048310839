import { createApp } from 'vue';
import Filter from '../components/filter/Filter.vue'
import store from '../vuex'
import { Tab } from "bootstrap";

export const HomepageView = {

  init() {
    const filter = document.getElementById('filter-wrapper')
    const niceUrls = JSON.parse(filter.dataset.niceUrls)
    // Counts filters changes to handle redirects.
    let filterChangeCount = 0
    let personalFilter = undefined
    let utilityFilter = undefined
    let modelType = undefined
    const buildBaseUrl = (modelType) => { return `/inzerce/${modelType === 'utility' ? 'uzitkova-auta' : 'osobni-auta'}` }

    document.addEventListener('show.bs.tab', function(e) {
      if(e.target.id === 'nav-personal-tab') {
        modelType = 'personal'
        if(personalFilter) {
          personalFilter.initialize()
        } else {
          personalFilter = createApp(Filter, { redirect: true, baseUrl: buildBaseUrl(modelType), modelType: modelType, niceUrls, saveLastSearchUrl: true })
            .use(store)
            .mount('#filter-wrapper-personal')
          if(filterChangeCount > 0) personalFilter.search()
        }
        window.filter = personalFilter
      } else if(e.target.id === 'nav-utility-tab') {
        modelType = 'utility'
        if(utilityFilter) {
          utilityFilter.initialize()
        } else {
          utilityFilter = createApp(Filter, { redirect: true, baseUrl: buildBaseUrl(modelType), modelType: modelType, niceUrls, saveLastSearchUrl: true })
            .use(store)
            .mount('#filter-wrapper-utility')
          if(filterChangeCount > 0) utilityFilter.search()
        }
        window.filter = utilityFilter
      }
      // Counts filter changes.
      filterChangeCount += 1
    })

    const tab = new Tab(document.querySelector('#nav-personal-tab'))
    tab.show()
  }

}