// Dokumentace:
// https://github.com/orestbida/cookieconsent/tree/v2.8
window.addEventListener('load', () => {
  // obtain plugin
  const cc = initCookieConsent();

  // run plugin with your configuration
  cc.run({
    current_lang: 'cs',
    autoclear_cookies: true, // default: false
    // page_scripts: true, // default: false
    // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
    // delay: 0,                               // default: 0
    // auto_language: '',                      // default: null; could also be 'browser' or 'document'
    // autorun: true,                          // default: true
    force_consent: false, // default: false
    // hide_from_bots: false,                  // default: false
    // remove_cookie_tables: false             // default: false
    // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
    // cookie_expiration: 182,                 // default: 182 (days)
    // cookie_necessary_only_expiration: 182   // default: disabled
    // cookie_domain: location.hostname,       // default: current domain
    // cookie_path: '/',                       // default: root
    // cookie_same_site: 'Lax',                // default: 'Lax'
    // use_rfc_cookie: false,                  // default: false
    // revision: 0,                            // default: 0
    gui_options: {
      consent_modal: {
        layout: 'cloud', // box/cloud/bar
        position: 'bottom left', // bottom/middle/top + left/right/center
        transition: 'slide', // zoom/slide
        swap_buttons: false, // enable to invert buttons
      },
      settings_modal: {
        layout: 'bar', // box/bar
        // position: 'left',           // left/right
        transition: 'slide', // zoom/slide
      },
    },

    onAccept(cookie) {
      const storages = {};

      if (cookie.level.includes('targeting')) {
        storages.ad_storage = 'granted';
      }
      if (cookie.level.includes('analytics')) {
        storages.analytics_storage = 'granted';
      }

      gtag('consent', 'update', storages);
      dataLayer.push({
        event: 'consent_state_update',
      });
    },

    languages: {
      cs: {
        consent_modal: {
          title: 'Používáme cookies',
          description:
            'Tento web používá soubory cookies. Používáním tohoto webu souhlasíte s ukládáním a používáním nezbytných souborů cookies. Zakliknutím pole "Přijmout vše" udělujete souhlas k ukládání a používání i dalších souborů cookies jako jsou analytické, preferenční, marketingové apod.',
          primary_btn: {
            text: 'Přijmout vše',
            role: 'accept_all', // 'accept_selected' or 'accept_all'
          },
          secondary_btn: {
            text: 'Nastavení',
            role: 'settings', // 'settings' or 'accept_necessary'
          },
        },
        settings_modal: {
          title: 'Cookies nastavení',
          save_settings_btn: 'Uložit nastavení',
          accept_all_btn: 'Přijmout vše',
          reject_all_btn: 'Odmítnout vše',
          close_btn_label: 'Zavřít',
          cookie_table_headers: [
            {
              col1: 'Jméno',
            },
            {
              col2: 'Doména',
            },
            {
              col3: 'Expirace',
            },
            {
              col4: 'Popis',
            },
          ],
          blocks: [
            {
              title: 'Využití cookies',
              description:
                'Soubory cookie používáme k zajištění základních funkcí webu a ke zlepšení vašeho online zážitku. Pro každou kategorii si můžete vybrat, zda se chcete přihlásit/odhlásit, kdykoli budete chtít.',
            },
            {
              title: 'Funkční/nezbytné cookies',
              description:
                'Tyto soubory cookie jsou nezbytné pro správné fungování webových stránek. Bez těchto cookies by web nefungoval správně. Nelze je tedy deaktivovat.',
              toggle: {
                value: 'necessary',
                enabled: true,
                readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
              },
              cookie_table: [
                // list of all expected cookies
                {
                  col1: 'cc_cookie,',
                  col2: '.prodamauto.cz',
                  col3: '6 měsíců',
                  col4: 'Soubor cookie cc_cookie využíváme pouze k informaci o Vašich preferenčních nastavení ohledně ostatních cookies.',
                },
              ],
            },
            {
              title: 'Analytické a preferenční cookies',
              description:
                'Analytické cookies nám umožňují sledovat návštěvy našich webových stránek a také to, kolik uživatelů kliklo na odkaz na našem webu či využilo některou z jeho funkcí. Typicky jde o statistiky návštěvnosti jako Google Analytics. Preferenční cookies zase umožňují, aby si náš web zapamatoval preference daného uživatele a mohl se mu přizpůsobit. Zajistí se tak pohodlné používání webu, který si pamatuje nastavení jazyka, měny a dalších preferencí uživatele.',
              toggle: {
                value: 'analytics', // your cookie category
                enabled: false,
                readonly: false,
              },
              cookie_table: [
                // list of all expected cookies
                {
                  col1: '^_ga', // match all cookies starting with "_ga"
                  col2: '.prodamauto.cz',
                  col3: '2 roky',
                  is_regex: true,
                  col4: 'Tento název souboru cookie je spojen s Google Universal Analytics. Tento soubor cookie se používá k rozlišení jedinečných uživatelů přiřazením náhodně vygenerovaného čísla jako identifikátoru klienta. Je součástí každé žádosti o stránku na webu a používá se k výpočtu údajů o návštěvnících, relacích a kampaních pro analytické přehledy stránek.',
                },
                {
                  col1: '^_utmt',
                  col2: '.prodamauto.cz',
                  col3: '10 minut',
                  col4: 'Jedná se o soubor cookie nastavený službou Google Analytics, kde prvek vzoru v názvu obsahuje jedinečné identifikační číslo účtu nebo webu, ke kterému se vztahuje. Používá se k omezení množství dat zaznamenaných společností Google na webových stránkách s vysokým objemem návštěvnosti.',
                  is_regex: true,
                },
                {
                  col1: '__utmb',
                  col2: '.prodamauto.cz',
                  col3: '30 minut',
                  col4: 'Jedná se o jeden ze čtyř hlavních souborů cookie nastavených službou Google Analytics, který umožňuje vlastníkům webových stránek sledovat chování návštěvníků a měřit výkon webu. Tento soubor cookie určuje nové relace a návštěvy a jeho platnost vyprší po 30 minutách. Soubor cookie se aktualizuje pokaždé, když jsou data odeslána do Google Analytics. Jakákoli aktivita uživatele v průběhu 30 minut se bude počítat jako jedna návštěva, a to i v případě, že uživatel web opustí a poté se na něj vrátí.',
                },
                {
                  col1: '__utmc',
                  col2: '.prodamauto.cz',
                  col3: 'Relace',
                  col4: 'Jedná se o jeden ze čtyř hlavních souborů cookie nastavených službou Google Analytics, který umožňuje vlastníkům webových stránek sledovat chování návštěvníků a měřit výkon webu. Na většině webů se nepoužívá, ale je nastaven tak, aby umožňoval interoperabilitu se starší verzí kódu Google Analytics známou jako Urchin. V těchto starších verzích to bylo používáno v kombinaci se souborem cookie __utmb k identifikaci nových relací/návštěv pro vracející se návštěvníky. Při použití službou Google Analytics se vždy jedná o soubor cookie relace, který je zničen, když uživatel zavře prohlížeč. Tam, kde je považován za trvalý soubor cookie, je tedy pravděpodobné, že se jedná o jinou technologii, která soubor cookie nastavuje.',
                },
                {
                  col1: '__utmz',
                  col2: '.prodamauto.cz',
                  col3: '6 měsíců a 2 dny',
                  col4: 'Jedná se o jeden ze čtyř hlavních souborů cookie nastavených službou Google Analytics, který umožňuje vlastníkům webových stránek sledovat chování návštěvníků a měřit výkon webu. Tento soubor cookie identifikuje zdroj návštěvnosti webu – Google Analytics tak může vlastníkům webu sdělit, odkud návštěvníci přišli, když na web přišli. Soubor cookie má životnost 6 měsíců a aktualizuje se pokaždé, když jsou data odeslána do Google Analytics.',
                },
                {
                  col1: '__utma',
                  col2: '.prodamauto.cz',
                  col3: '2 roky',
                  col4: 'Jedná se o jeden ze čtyř hlavních souborů cookie nastavených službou Google Analytics, který umožňuje vlastníkům webových stránek sledovat chování návštěvníků a měřit výkon webu. Tento soubor cookie ve výchozím nastavení trvá 2 roky a rozlišuje mezi uživateli a relacemi. Slouží k výpočtu statistik nových a vracejících se návštěvníků. Soubor cookie se aktualizuje pokaždé, když jsou data odeslána do Google Analytics.',
                },
                {
                  col1: '_gid',
                  col2: '.prodamauto.cz',
                  col3: '1 den',
                  col4: 'Tento soubor cookie je nastaven službou Google Analytics. Ukládá a aktualizuje jedinečnou hodnotu pro každou navštívenou stránku a používá se k počítání a sledování zobrazení stránek.',
                },
              ],
            },
            {
              title: 'Marketingové cookies',
              description:
                'Marketingové cookies se využívají k lepšímu cílení reklam (například remarketing, tedy zpětné oslovení uživatele, který už na našem webu byl, na jiném kanálu prostřednictvím placené reklamy) a k jejich personalizaci. Na základě těchto informací není zpravidla možná bezprostřední identifikace Vaší osoby, protože jsou používány pouze pseudonymizované údaje. Pokud nevyjádříte souhlas, nebudete příjemcem obsahů a reklam přizpůsobených Vašim zájmům.',
              toggle: {
                value: 'targeting',
                enabled: false,
                readonly: false,
              },
              // cookie_table: [
              //   // list of all expected cookies
              //   {
              //     col1: 'uid',
              //     col2: '.adform.net',
              //     col3: '2 měsíce',
              //     col4: 'Zaregistruje jedinečné ID uživatele, které rozpoznává prohlížeč uživatele při návštěvě webových stránek, které používají stejnou reklamní síť. Účelem je optimalizace zobrazování reklam na základě pohybů uživatele a nabídek různých poskytovatelů reklam za zobrazování reklam uživatelů.',
              //   },
              //   {
              //     col1: 'C',
              //     col2: '.adform.net',
              //     col3: '1 měsíc a 1 den',
              //     col4: 'Tento soubor cookie je nastaven společností Adform tak, aby umožňoval nabídky v reálném čase pro obsahovou reklamu. Slouží ke kontrole, zda prohlížeč uživatele podporuje soubory cookie.',
              //   },
              //   {
              //     col1: 'IDE',
              //     col2: '.doubleclick.net',
              //     col3: '2 roky',
              //     col4: 'Používá ho Google DoubleClick k registraci a hlášení akcí uživatele webových stránek po zobrazení nebo kliknutí na některou z reklam inzerenta za účelem měření účinnosti reklamy a prezentace cílených reklam uživateli.',
              //   },
              //   {
              //     col1: 'test_cookie',
              //     col2: '.doubleclick.net',
              //     col3: '15 minut',
              //     col4: 'Slouží ke kontrole, zda prohlížeč uživatele podporuje soubory cookie.',
              //   },
              //   {
              //     col1: 'sid',
              //     col2: '.seznam.cz',
              //     col3: '1 měsíc',
              //     col4: 'Zachované stavy uživatelů napříč požadavky na stránky.',
              //   },
              //   {
              //     col1: '_fbp',
              //     col2: '.prodamauto.cz',
              //     col3: '3 měsíce',
              //     col4: 'Používáno Facebookem pro poskytování řady reklamních produktů, jako jsou nabídky v reálném čase od inzerentů třetích stran.',
              //   },
              //   {
              //     col1: '_gcl_au',
              //     col2: '.prodamauto.cz',
              //     col3: '3 měsíce',
              //     col4: 'Používá Google AdSense k experimentování s efektivitou reklamy na webech využívajících jejich služby.',
              //   },
              // ],
            },
            {
              title: 'Více informací',
              description:
                'Máte-li jakékoli dotazy týkající se našich zásad týkajících se souborů cookie a vašich voleb, <a class="cc-link" target="_blank" href="mailto:info@prodamauto.cz">kontaktujte nás</a>.',
            },
          ],
        },
      },
    },
  });
});
