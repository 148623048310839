<template>
  <div id="filter">
    <div class="row gx-2">
      <FilterTextInput :id="makeElementId('brands-input')" :modalId="makeElementId('brands-modal')" label="Značka" :selected-options="selectedBrands" @reset="selectBrands([])"/>
      <FilterTextInput :id="makeElementId('models-input')" :modalId="makeElementId('models-modal')" label="Model" :selected-options="selectedModels" @reset="selectModels([])" :is-disabled="modelInputDisabled"/>
      <FilterTextInput :id="makeElementId('bodies-input')" :modalId="makeElementId('bodies-modal')" label="Karosérie" :selected-options="selectedBodies" @reset="selectBodies([])"/>
      <FilterTextInput :id="makeElementId('fuels-input')" :modalId="makeElementId('fuels-modal')" label="Palivo" :selected-options="selectedFuels" @reset="selectFuels([])"/>
      <FilterRangeInput :id="makeElementId('price-range-input')" :modalId="makeElementId('price-range-modal')" label="Cena (Kč)" :selected-options="selectedPriceRange" @reset="selectPriceRange({})"/>
    </div>
    <div class="collapse" :id="collapseButtonId">
      <div class="row gx-2">
        <FilterRangeInput :id="makeElementId('displacement-input')" :modalId="makeElementId('displacement-modal')" label="Objem (ccm)" :selected-options="selectedDisplacement" @reset="selectDisplacement({})"/>
        <FilterRangeInput :id="makeElementId('engine-power-input')" :modalId="makeElementId('engine-power-modal')" label="Výkon (kW)" :selected-options="selectedEnginePower" @reset="selectEnginePower({})"/>
        <FilterTextInput :id="makeElementId('gearboxes-input')" :modalId="makeElementId('gearboxes-modal')" label="Převodovka" :selected-options="selectedGearboxes" @reset="selectGearboxes([])"/>
        <FilterTextInput :id="makeElementId('wheel-drives-input')" :modalId="makeElementId('wheel-drives-modal')" label="Pohon" :selected-options="selectedWheelDrives" @reset="selectWheelDrives([])"/>
        <FilterTextInput :id="makeElementId('seats-input')" :modalId="makeElementId('seats-modal')" label="Počet míst" :selected-options="selectedSeats" @reset="selectSeats([])"/>
      </div>
      <div class="row gx-2">
        <FilterTextInput :id="makeElementId('car-equipments-input')" :modalId="makeElementId('car-equipments-modal')" label="Výbava" :selected-options="selectedCarEquipments" @reset="selectCarEquipments([])"/>
        <FilterTextInput :id="makeElementId('countries-input')" :modalId="makeElementId('countries-modal')" label="Země původu" :selected-options="selectedCountries" @reset="selectCountries([])"/>
        <FilterTextInput :id="makeElementId('car-states-input')" :modalId="makeElementId('car-states-modal')" label="Stav" :selected-options="selectedCarStates" @reset="selectCarStates([])"/>
        <FilterRangeInput :id="makeElementId('mileage-input')" :modalId="makeElementId('mileage-range-modal')" label="Stav tachometru (km)" :selected-options="selectedMileage" @reset="selectMileage({})"/>
        <FilterRangeInput :id="makeElementId('rides-since-year-input')" :modalId="makeElementId('rides-since-year-modal')" label="Uvedení do provozu" :selected-options="selectedRidesSinceYear" @reset="selectRidesSinceYear({})"/>
      </div>
    </div>
    <button class="filter-expander box-shadow-1 collapsed" data-bs-toggle="collapse" :data-bs-target="`#${collapseButtonId}`">
      Rozšířený filtr <i class="icon icon-caret-down icon--md"></i><i class="icon icon-caret-up icon--md"></i>
    </button>

    <MultiSelectionModal :modal-id="makeElementId('brands-modal')" :options="brands" :saved-options="selectedBrands" title="Zvolte značku" :cssClasses="['modal-xl']" @selected="selectBrands" />
    <MultiSelectionGroupModal :modal-id="makeElementId('models-modal')" :groups="models" :saved-options="selectedModels" title="Zvolte model" :cssClasses="['modal-xl']" @selected="selectModels" />
    <MultiImageSelectionModal :modal-id="makeElementId('bodies-modal')" :options="bodies" :saved-options="selectedBodies" title="Zvolte karosérii" :cssClasses="['modal-xl']" @selected="selectBodies"/>
    <MultiSelectionModal :modal-id="makeElementId('fuels-modal')" :options="fuels" :saved-options="selectedFuels" title="Zvolte palivo" :cssClasses="['modal-xl']" @selected="selectFuels" />
    <RangeSelectionModal :modal-id="makeElementId('price-range-modal')" :options="prices" :saved-range="selectedPriceRange" :url-params="urlParams" query-name="price" title="Zvolte cenu" :cssClasses="['modal-sm']" @selected="selectPriceRange" unit="Kč" />
    <MultiSelectionModal :modal-id="makeElementId('gearboxes-modal')" :options="gearboxes" :saved-options="selectedGearboxes"  title="Zvolte převodovku" :cssClasses="['modal-xl']" @selected="selectGearboxes" />
    <MultiSelectionModal :modal-id="makeElementId('wheel-drives-modal')" :options="wheelDrives" :saved-options="selectedWheelDrives" title="Zvolte palivo" :cssClasses="['modal-xl']" @selected="selectWheelDrives" />
    <RangeSelectionModal :modal-id="makeElementId('displacement-modal')" :options="displacements" :saved-range="selectedDisplacement" :url-params="urlParams" query-name="displacement" title="Zvolte objem" :cssClasses="['modal-xs']" @selected="selectDisplacement" unit="ccm" />
    <RangeSelectionModal :modal-id="makeElementId('engine-power-modal')" :options="enginePowers" :saved-range="selectedEnginePower" :url-params="urlParams" query-name="engine_power" title="Zvolte objem" :cssClasses="['modal-xs']" @selected="selectEnginePower" unit="kW" />
    <MultiSelectionModal :modal-id="makeElementId('seats-modal')" :options="seats" :saved-options="selectedSeats" title="Zvolte počet sedadel" :cssClasses="['modal-xs']" @selected="selectSeats" />
    <MultiSelectionModal :modal-id="makeElementId('countries-modal')" :options="countries" :saved-options="selectedCountries" title="Zvolte zemi původu" :cssClasses="['modal-xl']" @selected="selectCountries" />
    <MultiSelectionModal :modal-id="makeElementId('car-states-modal')" :options="carStates" :saved-options="selectedCarStates" title="Zvolte stav" :cssClasses="['modal-xl']" @selected="selectCarStates" />
    <RangeSelectionModal :modal-id="makeElementId('mileage-range-modal')" :options="mileages" :saved-range="selectedMileage" :url-params="urlParams" query-name="mileage" title="Zvolte stav tachometru" :cssClasses="['modal-xs']" @selected="selectMileage" unit="km" />
    <RangeSelectionModal :modal-id="makeElementId('rides-since-year-modal')" :options="ridesSinceYears" :reversed-options="true" :saved-range="selectedRidesSinceYear" :url-params="urlParams" :formatted="false" query-name="rides_since_year" title="Zvolte uvedené do provozu" :cssClasses="['modal-xs']" @selected="selectRidesSinceYear" unit="" />
    <MultiSelectionModal :modal-id="makeElementId('car-equipments-modal')" :options="carEquipments" :saved-options="selectedCarEquipments" title="Zvolte výbavu" :cssClasses="['modal-xl']" :immediateSave="true" @selected="selectCarEquipments" />
  </div>
</template>

<script>
import {camelCase, isEmpty, isNumber, isEqual, mapKeys, range, groupBy} from "lodash";
import FilterTextInput from './FilterTextInput.vue'
import FilterRangeInput from './FilterRangeInput.vue'
import RangeSelectionModal from "../modals/RangeSelectionModal.vue"
import MultiSelectionModal from "../modals/MultiSelectionModal.vue"
import ApiService from "../../services/apiService.js"
import axios from "axios";
import MultiImageSelectionModal from "../modals/MultiImageSelectionModal.vue";
import MultiSelectionGroupModal from "../modals/MultiSelectionGroupModal.vue";

const objectMapper = (array) => {
  return array.map((v, i) => {
    return { id: i, value: v.toString() }
  })
}

export default {
  name: 'Filter',

  components: {
    MultiSelectionGroupModal,
    MultiImageSelectionModal,
    MultiSelectionModal,
    RangeSelectionModal,
    FilterTextInput,
    FilterRangeInput,
  },

  emits: ['search'],

  props: {
    baseUrl: {
      type: String,
      required: true
    },

    niceUrls: {
      type: Array,
      default: () => []
    },

    modelType: {
      type: String,
      default: 'personal'
    },

    advertisementType: {
      type: String,
    },

    userSlug: {
      type: String,
    },

    redirect: {
      type: Boolean,
      default: false
    },

    searchParams: {
      type: String,
      default: undefined
    },

    advertisementsCount: {
      type: Number,
      default: 0
    },

    style: {
      type: String,
      default: 'default'
    },

    saveLastSearchUrl: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      urlParams: '',
      brands: [],
      models: [],
      bodies: [],
      fuels: [],
      gearboxes: [],
      wheelDrives: [],
      carEquipments: [],
      displacements: [],
      enginePowers: [],
      seats: [],
      prices: [],
      carStates: [],
      mileages: [],
      ridesSinceYears: [],//objectMapper(range(1970, new Date().getFullYear() + 1, 1).reverse()),
      countries: [],
      selectedBrands: [],
      selectedModels: [],
      selectedBodies: [],
      selectedFuels: [],
      selectedPriceRange: undefined,
      selectedDisplacement: undefined,
      selectedEnginePower: undefined,
      selectedRidesSince: undefined,
      selectedRidesSinceYear: undefined,
      selectedMileage: undefined,
      selectedGearboxes: [],
      selectedWheelDrives: [],
      selectedSeats: [],
      selectedCountries: [],
      selectedCarStates: [],
      selectedCarEquipments: [],
    }
  },

  computed: {
    openModalId() {
      return this.$store.state.ModalStore.openedModalId
    },
    modelInputDisabled() {
      return isEmpty(this.selectedBrands)
    },
    collapseButtonId() {
      return `collapseFilter-${this.modelType === 'personal' ? 'p' : 'u'}`
    }
  },

  watch: {
    async openModalId(value) {
      await this.loadResources(value)
    },
  },

  methods: {
    reset(skipSearch) {
      this.selectBrands([], skipSearch)
      this.selectModels([], skipSearch)
      this.selectBodies([], skipSearch)
      this.selectFuels([], skipSearch)
      this.selectPriceRange({}, skipSearch)
      this.selectDisplacement([], skipSearch)
      this.selectEnginePower({}, skipSearch)
      this.selectGearboxes([], skipSearch)
      this.selectWheelDrives([], skipSearch)
      this.selectSeats([], skipSearch)
      this.selectCountries([], skipSearch)
      this.selectCarStates([], skipSearch)
      this.selectMileage({}, skipSearch)
      this.selectRidesSinceYear({}, skipSearch)
      this.selectCarEquipments([], skipSearch)
    },

    async loadResources(value) {
      const queryString = this.findQueryString()
      let params = new URLSearchParams(queryString)
      params.append('count', 'true')
      params.append('model_type', this.modelType)
      if(!isEmpty(this.advertisementType)) params.append('advertisement_type', this.advertisementType )
      if(!isEmpty(this.userSlug)) params.append('user_slug', this.userSlug.toString() )
      this.urlParams = params.toString()
      switch(value) {
        case this.makeElementId('brands-modal'):
          const brands = await this.loadResource(`brands?${params.toString()}`)
          this.brands = brands.map((i) => {
            return { id: i.id, value: i.name, label: i.name, count: i.count }
          })
          break;
        case this.makeElementId('models-modal'):
          const models = await this.loadResource(`models?${params.toString()}`)
          const mappedModels = models.map((i) => {
            return { id: i.id, value: i.name, label: i.name, brandId: i.brand_id, brandName: i.brand_name, count: i.count }
          })
          this.models = groupBy(mappedModels, (model) => {
            return model.brandId
          })
          break;
        case this.makeElementId('bodies-modal'):
          const bodies = await this.loadResource(`bodies?${params.toString()}`)
          this.bodies = bodies.map((i) => {
            return { id: i.id, value: i.name, label: i.name, count: i.count, iconName: i.iconName }
          })
          break;
        case this.makeElementId('gearboxes-modal'):
          const gearboxes = await this.loadResource(`gearboxes?${params.toString()}`)
          this.gearboxes = gearboxes.map((i) => {
            return { id: i.id, value: i.name, label: i.name, count: i.count }
          })
          break;
        case this.makeElementId('wheel-drives-modal'):
          const wheelDrives = await this.loadResource(`wheel_drives?${params.toString()}`)
          this.wheelDrives = wheelDrives.map((i) => {
            return { id: i.id, value: i.name, label: i.name, count: i.count }
          })
          break;
        case this.makeElementId('fuels-modal'):
          const fuels = await this.loadResource(`fuels?${params.toString()}`)
          this.fuels = fuels.map((i) => {
            return { id: i.id, value: i.name, label: i.name, count: i.count }
          })
          break;
        case this.makeElementId('car-equipments-modal'):
          const carEquipments = await this.loadResource(`car_equipments?${params.toString()}`)
          this.carEquipments = carEquipments.map((i) => {
            return { id: i.id, value: i.name, label: i.name, count: i.count }
          })
          break;
        case this.makeElementId('price-range-modal'):
          const priceRange = await this.loadResource(`price_range?${params.toString()}`)
          this.prices = priceRange.map((v, i) => {
            return { id: i.id, value: v.toString() }
          })
          break;
        case this.makeElementId('displacement-modal'):
          const displacements = await this.loadResource(`displacements?${params.toString()}`)
          this.displacements = displacements.map((v, i) => {
            return { id: i.id, value: v.toString() }
          })
          break;
        case this.makeElementId('engine-power-modal'):
          const enginePowers = await this.loadResource(`engine_powers?${params.toString()}`)
          this.enginePowers = enginePowers.map((v, i) => {
            return { id: i.id, value: v.toString() }
          })
          break;
        case this.makeElementId('seats-modal'):
          const seats = await this.loadResource(`seats?${params.toString()}`)
          this.seats = seats.map((i) => {
            return { id: i.id, value: i.name, label: i.name, count: i.count }
          })
          break;
        case this.makeElementId('countries-modal'):
          const countries = await this.loadResource(`countries?${params.toString()}`)
          this.countries = countries.map((i) => {
            return { id: i.id, value: i.name, label: i.name, count: i.count }
          })
          break;
        case this.makeElementId('car-states-modal'):
          const carStates = await this.loadResource(`car_states?${params.toString()}`)
          this.carStates = carStates.map((i) => {
            return { id: i.id, value: i.name, label: i.name, count: i.count }
          })
          break;
        case this.makeElementId('mileage-range-modal'):
          const mileageRange = await this.loadResource(`mileage_range?${params.toString()}`)
          this.mileages = mileageRange.map((v, i) => {
            return { id: i.id, value: v.toString() }
          })
          break;
        case this.makeElementId('rides-since-year-modal'):
          const ridesSinceRange = await this.loadResource(`rides_since_range?${params.toString()}`)
          this.ridesSinceYears = ridesSinceRange.map((v, i) => {
            return { id: i.id, value: v.toString() }
          })
          break;
        default:
          // Clears all options in resource loaded modals.
          this.brands.splice(0, this.brands.length)
          this.models = null
          this.bodies.splice(0, this.bodies.length)
          this.gearboxes.splice(0, this.gearboxes.length)
          this.wheelDrives.splice(0, this.wheelDrives.length)
          this.fuels.splice(0, this.fuels.length)
          this.prices.splice(0, this.prices.length)
          this.displacements.splice(0, this.displacements.length)
          this.carEquipments.splice(0, this.carEquipments.length)
          this.seats.splice(0, this.seats.length)
          this.countries.splice(0, this.countries.length)
          this.carStates.splice(0, this.carStates.length)
          this.mileages.splice(0, this.mileages.length)
      }
    },

    async search(options = {}) {
      const url = this.buildUrl(options)
      if(isEmpty(url)) return
      if(this.saveLastSearchUrl) {
        localStorage.lastSearchFilterUrl = url
      }
      if(this.redirect) {
        window.location.href = url
      } else {
        await this.searchAdvertisements(url)
      }
    },

    async searchAdvertisements(url) {
      const previousUrl = window.location.href
      const { data } = await axios.get(url, {
        headers: {
          // Overwrite Axios's automatically set Content-Type
          'Accept': 'application/json'
        }
      })
      if(data.advertisementsCount > 0) {
        window.history.pushState({}, '', url)
        const advertisementCounters = document.querySelectorAll('.advertisements-count')
        for (const el of advertisementCounters) { el.textContent = data.advertisementsCount }
        if (data.title) document.title = data.title
        if (data.headline) document.querySelector('h1').innerHTML = data.headline
        document.querySelector('.js-car-list').innerHTML = data.html
      } else {
        alert('Počet inzerátů je 0.')
        window.location.reload()
      }
    },

    buildUrl(options) {
      const params = this.buildParams(options).toString()
      const niceUrl = this.findNiceUrl(params)
      if(niceUrl) {
        return niceUrl.url
      } else {
        return params.length > 0 ? `${this.baseUrl}?${params}` : `${this.baseUrl}`
      }
    },

    buildParams(options) {
      const params = new URLSearchParams()
      if(!isEmpty(options?.sort)) params.append('sort', options.sort)
      if(!isEmpty(this.selectedBrands)) params.append('brands', this.selectedBrands.map((o) => o.id).join(','))
      if(!isEmpty(this.selectedModels)) params.append('models', this.selectedModels.map((o) => o.id).join(','))
      if(!isEmpty(this.selectedBodies)) params.append('bodies', this.selectedBodies.map((o) => o.id).join(','))
      if(!isEmpty(this.selectedGearboxes)) params.append('gearboxes', this.selectedGearboxes.map((o) => o.id).join(','))
      if(!isEmpty(this.selectedWheelDrives)) params.append('wheel_drives', this.selectedWheelDrives.map((o) => o.id).join(','))
      if(!isEmpty(this.selectedFuels)) params.append('fuels', this.selectedFuels.map((o) => o.id).join(','))
      if(!isEmpty(this.selectedPriceRange)) params.append('price', `${this.selectedPriceRange.from}-${this.selectedPriceRange.to}` )
      if(!isEmpty(this.selectedDisplacement)) params.append('displacement', `${this.selectedDisplacement.from}-${this.selectedDisplacement.to}` )
      if(!isEmpty(this.selectedEnginePower)) params.append('engine_power', `${this.selectedEnginePower.from}-${this.selectedEnginePower.to}` )
      if(!isEmpty(this.selectedSeats)) params.append('seats_count', this.selectedSeats.map((o) => o.id).join(',') )
      if(!isEmpty(this.selectedCountries)) params.append('countries', this.selectedCountries.map((o) => o.id).join(',') )
      if(!isEmpty(this.selectedCarEquipments)) params.append('car_equipments', this.selectedCarEquipments.map((o) => o.id).join(',') )
      if(!isEmpty(this.selectedCarStates)) params.append('car_states', this.selectedCarStates.map((o) => o.id).join(',') )
      if(!isEmpty(this.selectedMileage)) params.append('mileage', `${this.selectedMileage.from}-${this.selectedMileage.to}` )
      if(!isEmpty(this.selectedRidesSinceYear)) params.append('rides_since_year', `${this.selectedRidesSinceYear.from}-${this.selectedRidesSinceYear.to}` )
      if(!isEmpty(this.advertisementType)) params.append('advertisement_type', `${this.advertisementType}` )
      if(!isEmpty(this.style) && this.style !== 'default') params.append('style', this.style )
      return params
    },

    findNiceUrl(params) {
      return this.niceUrls.find(u => isEqual(u.params_query, params))
    },

    findNiceUrlByPathname(pathname) {
      const urls = this.niceUrls.filter(u => u.url === pathname)
      return urls.length > 0 ? urls[0] : null
    },

    async loadResource(resource) {
      const { data } = await ApiService.get(resource)
      return data[Object.keys(data)[0]]
    },

    async loadFilterOptions() {
      const queryString = this.findQueryString()
      if(queryString.length > 2) {
        const { data } = await ApiService.get(`filter_params${queryString}`)
        if(data.brands) this.selectBrands(data.brands, true)
        if(data.models) this.selectModels(data.models, true)
        if(data.bodies) this.selectBodies(data.bodies, true)
        if(data.gearboxes) this.selectGearboxes(data.gearboxes, true)
        if(data.wheel_drives) this.selectWheelDrives(data.wheel_drives, true)
        if(data.fuels) this.selectFuels(data.fuels, true)
        if(data.price) this.selectPriceRange(data.price, true)
        if(data.displacement) this.selectDisplacement(data.displacement, true)
        if(data.engine_power) this.selectEnginePower(data.engine_power, true)
        if(data.seats_count) this.selectSeats(data.seats_count, true)
        if(data.countries) this.selectCountries(data.countries, true)
        if(data.car_equipments) this.selectCarEquipments(data.car_equipments, true)
        if(data.car_states) this.selectCarStates(data.car_states, true)
        if(data.mileage) this.selectMileage(data.mileage, true)
        if(data.rides_since_year) this.selectRidesSinceYear(data.rides_since_year, true)
      }
    },

    findQueryString() {
      let queryString = window.location.search
      if(!queryString || queryString === '') {
        const niceUrl = this.findNiceUrlByPathname(window.location.pathname)
        if(niceUrl) queryString = `?${niceUrl.params_query}`
      }
      return queryString
    },

    initialize() {
      this.reset(true)
      this.search()
    },

    selectBrands(values, skipSearch = false) {
      this.selectedBrands = values
      if(!skipSearch) this.search()
    },

    selectModels(values, skipSearch = false) {
      this.selectedModels = values
      if(!skipSearch) this.search()
    },

    selectBodies(values, skipSearch = false) {
      this.selectedBodies = values
      if(!skipSearch) this.search()
    },

    selectFuels(values, skipSearch = false) {
      this.selectedFuels = values
      if(!skipSearch) this.search()
    },

    selectPriceRange(value, skipSearch = false) {
      this.selectedPriceRange = value
      if(!skipSearch) this.search()
    },

    selectDisplacement(value, skipSearch = false) {
      this.selectedDisplacement = value
      if(!skipSearch) this.search()
    },

    selectEnginePower(value, skipSearch = false) {
      this.selectedEnginePower = value
      if(!skipSearch) this.search()
    },

    selectGearboxes(values, skipSearch = false) {
      this.selectedGearboxes = values
      if(!skipSearch) this.search()
    },

    selectWheelDrives(values, skipSearch = false) {
      this.selectedWheelDrives = values
      if(!skipSearch) this.search()
    },

    selectSeats(values, skipSearch = false) {
      this.selectedSeats = values
      if(!skipSearch) this.search()
    },

    selectCountries(values, skipSearch = false) {
      this.selectedCountries = values
      if(!skipSearch) this.search()
    },

    selectCarStates(values, skipSearch = false) {
      this.selectedCarStates = values
      if(!skipSearch) this.search()
    },

    selectMileage(value, skipSearch = false) {
      this.selectedMileage = value
      if(!skipSearch) this.search()
    },

    selectRidesSinceYear(value, skipSearch = false) {
      this.selectedRidesSinceYear = value
      if(!skipSearch) this.search()
    },

    async selectCarEquipments(value, skipSearch = false) {
      this.selectedCarEquipments = value
      if(!skipSearch) await this.search()
      await this.loadResources(this.makeElementId('car-equipments-modal'))
    },

    makeElementId(value) {
      return `${this.modelType}-${value}`
    },
  },
}
</script>
