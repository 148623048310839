<template>
  <div class="col-12 col-sm-4 col-md-4 col-lg-3 mb-1">
    <div class="form-check">
      <input @input="check"
      type="checkbox"
      :checked="checked"
      :id="fieldId"
      v-bind="$attrs"
      :disabled="disabled"
      class="form-check-input">
      <label class="form-check-label" :for="fieldId">
        {{ option.value }} <span class="form-check-sub-label">({{ option.count }})</span>
      </label>
    </div>
  </div>
</template>

<script>
import {computed} from "vue";

export default {
  name: 'FilterCheckbox',
  emits: ["toggled"],
  props: {
    option: {
      type: Object,
      required: true,
    },
    fieldId: {
      type: Number,
      required: true,
    },
    selectedOptions: {
      type: Array,
      required: true,
    },
  },
  setup(props, context) {
    const index = props.selectedOptions.findIndex(o => {
      return o.id === props.option.id
    })
    const checked = index >= 0
    const disabled = computed(() => props.option.count <= 0)
    const check = () => {
      context.emit("toggled", props.option);
    }

    return {
      checked,
      disabled,
      check,
    };
  },
}
</script>