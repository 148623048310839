<template>
  <div class="col-lg mt-lg-3 mt-2">
    <label :for="id" class="form-label" :class="{ 'form-label-disabled': isDisabled }">
      {{ label }}
      <button @click="reset" v-show="showLabel" class="form-label__reset js-reset-input text-danger">
        <i class="icon icon--sm icon-dismiss-circle bg-danger"></i> reset
      </button>
    </label>
    <div @click="openModal(modalId)" class="form-control-wrap form-control-wrap--lg" :class="{ 'form-control-wrap--clickable': !isDisabled }">
      <input type="text" class="form-control form-control-lg has-icon" :class="{ 'is-filled': showLabel }" readonly :id="id" :value="selectedOptionsLabel" placeholder="Vyberte">
      <label v-if="showLabel" class="form-control__number form-control__number--lg" :for="id">{{ selectedOptions.length }}</label>
      <label v-else class="icon icon--lg icon-add-circle bg-gray-700" :for="id"></label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilterTextInput',

  emits: ["reset"],

  props: {
    id: {
      type: String,
      required: true
    },
    selectedOptions: {
      type: Array,
      default: undefined
    },
    modalId: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
  },

  computed: {
    selectedOptionsLabel() {
      return this.selectedOptions.map((o) => o.value).join(', ')
    },
    showLabel() {
      return this.selectedOptions && this.selectedOptions.length > 0
    }
  },
  methods: {
    openModal(modalId) {
      if(!this.isDisabled) {
        this.$store.dispatch('ModalStore/openModal', {
          id: modalId
        })
      }
    },

    reset() {
      this.$emit('reset')
    }
  },
}
</script>