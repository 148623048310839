document.addEventListener("DOMContentLoaded", function(event) { 
  var carList = document.getElementsByClassName('js-car-list')[0];
  var filterSelection = document.getElementsByClassName('filter-selection')[0];

  if (typeof carList !== 'undefined' && typeof filterSelection !== 'undefined') {
    window.addEventListener('scroll', function() {
      let scrollPosition = window.scrollY;
      let carListOffset = offset(carList);
      let navHeight = document.getElementsByClassName('header')[0]. offsetHeight;

      if(scrollPosition && scrollPosition > (carListOffset.top-navHeight)) {
        filterSelection.classList.add('filter-selection--fixed');
      } else {
        filterSelection.classList.remove('filter-selection--fixed');
      }
    });
  }
});

function offset(el) {
    var rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}