<template>
  <BaseModal :modal-id="modalId" :css-classes="['modal-xl']" :title="params.title">
    <div class="mb-1 mt-2 mb-md-3 mt-md-4">
      <p>{{ params.message }}</p>
      <p>{{ params.advertisementUrl }}</p>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from "../modals/BaseModal.vue";
export default {
  name: 'ModalAlert',

  components: {
    BaseModal
  },

  data() {
    return {
      modalId: 'modal-alert',
    }
  },

  computed: {
    params() {
      return this.$store.getters['ModalStore/params']
    }
  },
}
</script>