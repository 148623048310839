import { Modal } from 'bootstrap';

export const StickerModal = {
  init() {
    const stickerModalEl = document.getElementById('advertisementsStickerModal');
    const stickerModalInstance = new Modal(document.getElementById('advertisementsStickerModal'))
    const advertisementSelect = document.getElementById('advertisement-select');
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    
    //select advertisement by URL param
    if(urlParams.has('stickers_for')) {
      //select advertisement + set read only
      advertisementSelect.value = urlParams.get('stickers_for');
      setReadOnly(advertisementSelect, true);
      //open sticker modal
      stickerModalInstance.show();
      //remove param from url
      history.replaceState && history.replaceState(
        null, '', location.pathname + location.search.replace(/[\?&]stickers_for=[^&]+/, '').replace(/^&/, '?')
      );
    }

    //select advertisement by data-attribute - click to button
    stickerModalEl.addEventListener('show.bs.modal', (event) => {
      const button = event.relatedTarget;
      let advertisementId = button.dataset.advid;
      if (typeof advertisementId !== 'undefined' && advertisementId !== null) {
        //select advertisement + set read only
        advertisementSelect.value = advertisementId;
        setReadOnly(advertisementSelect, true);
      }
    });


    stickerModalEl.addEventListener('hide.bs.modal', () => {
      //reset advertisement
      advertisementSelect.selectedIndex = 0;
      setReadOnly(advertisementSelect, false)
    });
    
  },
};

const setReadOnly = function (selectElement, isReadOnly) {
  selectElement.onclick = function () {
    return !isReadOnly ;
  };
  selectElement.onkeydown =function(){
      return !isReadOnly ;
  } ;
  selectElement.style.pointerEvents = isReadOnly ? "none" : "all" ;
  selectElement.style.opacity = isReadOnly ? "0.5" : "1" ;
};
