document.addEventListener("DOMContentLoaded", function(event) { 

//selected cars
let carCheckboxes = document.querySelectorAll('.car-card input[type=checkbox]');
  carCheckboxes.forEach(function (carCheckbox) {
    carCheckbox.addEventListener('change', function() {
      toggleSelectedCar(carCheckbox);
    });
    toggleSelectedCar(carCheckbox);
  });

//selected inquiry
let inquiryCheckboxes = document.querySelectorAll('.inquiry-card input[type=checkbox]');
  inquiryCheckboxes.forEach(function (inquiryCheckbox) {
    inquiryCheckbox.addEventListener('change', function() {
      toggleSelectedInquiry(inquiryCheckbox);
    });
    toggleSelectedInquiry(inquiryCheckbox);
  });

  //check all
  let checksAll = document.querySelectorAll('.js-check-all');
  checksAll.forEach(function (checkAll) {
      checkAll.addEventListener('change', function() {
        checkAllCards(checkAll);
      });
      checkAllCards(checkAll);
    });

});


function toggleSelectedCar(carCheckbox) {
  if(carCheckbox.checked === true) {
      carCheckbox.closest('.car-card').classList.add('car-card--selected');
    } else {
      carCheckbox.closest('.car-card').classList.remove('car-card--selected');
    }
}

function toggleSelectedInquiry(inquiry) {
  if(inquiry.checked === true) {
      inquiry.closest('.inquiry-card').classList.add('inquiry-card--selected');
    } else {
      inquiry.closest('.inquiry-card').classList.remove('inquiry-card--selected');
    }
}

function checkAllCards(checkAll) {
  let areaId = checkAll.value
  let cardCheckboxes = document.querySelectorAll('#'+areaId+' input[type=checkbox]');
  if(checkAll.checked === true) { 
    cardCheckboxes.forEach(function (cardCheckbox) {
      cardCheckbox.checked = true;
      cardCheckbox.dispatchEvent(new window.Event('change', { bubbles: true }))
    });
  } else {
    cardCheckboxes.forEach(function (cardCheckbox) {
      cardCheckbox.checked = false;
      cardCheckbox.dispatchEvent(new window.Event('change', { bubbles: true }))
    });
  }
  
}