import Litepicker from 'litepicker';
import 'litepicker/dist/plugins/mobilefriendly';
//Documentation: https://litepicker.com/#getting-started
window.disableLitepickerStyles = true;

document.addEventListener("DOMContentLoaded", function(event) { 

  //Seller Profile
  var pubFrom = document.getElementById('pub-from');
  var pubTo = document.getElementById('pub-to');

  if (typeof pubFrom !== 'undefined' && pubFrom !== null && typeof pubTo !== 'undefined' && pubTo !== null) {
    const pubPicker = new Litepicker({ 
        element: document.getElementById('pub-from'),
        elementEnd: document.getElementById('pub-to'),
        plugins: ['mobilefriendly'],
        singleMode: false,
        numberOfMonths: 2,
        numberOfColumns: 2,
        format: "DD.MM.YYYY",
        lang: "cs-CZ",
        allowRepick: false,
        tooltipText: {
          one: 'den',
          other: 'dní'
        },
        mobilefriendly: {
          breakpoint: 700,
        },
      });
  }

    var expFrom = document.getElementById('exp-from');
    var expTo = document.getElementById('exp-to');

    if (typeof expFrom !== 'undefined' && expFrom !== null && typeof expTo !== 'undefined' && expTo !== null) {
      const expPicker = new Litepicker({ 
        element: document.getElementById('exp-from'),
        elementEnd: document.getElementById('exp-to'),
        plugins: ['mobilefriendly'],
        singleMode: false,
        numberOfMonths: 2,
        numberOfColumns: 2,
        format: "DD.MM.YYYY",
        lang: "cs-CZ",
        allowRepick: false,
        tooltipText: {
          one: 'den',
          other: 'dní'
        },
        mobilefriendly: {
          breakpoint: 700,
        },
      });
    }


})
