import 'cropperjs/dist/cropper.css';
import Cropper from 'cropperjs';
//dokumentace: https://github.com/fengyuanchen/cropperjs/blob/main/README.md

window.addEventListener('DOMContentLoaded', function () {
  var photoCropModal = document.getElementById('photoCropModal')
  var image = document.getElementById('imageToCrop');
  var saveCroppedImg = document.getElementById('save-cropped-img');
  var cropBoxData;
  var canvasData;
  var cropper;

  if (typeof photoCropModal !== 'undefined' && photoCropModal !== null) {
    photoCropModal.addEventListener('shown.bs.modal', function (event) {
      cropper = new Cropper(image, {
        viewMode: 3,
        aspectRatio: 4 / 3,
        responsive: true,
        zoomable: false,
        ready: function () {
          //Should set crop box data first here
          cropper.setCropBoxData(cropBoxData).setCanvasData(canvasData);
        }
      });
    });

    saveCroppedImg.addEventListener('click', function (event) {
      cropBoxData = cropper.getCropBoxData();
      canvasData = cropper.getCanvasData();
      //console.log(cropBoxData);
      console.log(canvasData);
      alert('Ořezová data najdete v consoli');
    });
    
    photoCropModal.addEventListener('hidden.bs.modal', function (event) {
      cropper.destroy();
    });
}

});
  