import { Modal } from 'bootstrap';

// core version + navigation, pagination modules:
import Swiper from 'swiper';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/zoom';

// configure Swiper to use modules
import { Navigation, Keyboard, Mousewheel, Zoom,  Controller, Thumbs } from 'swiper/modules';

window.addEventListener('DOMContentLoaded', function () {

  let carGalleryModalEl = document.getElementById('carGalleryModal');
  let carGalleryModaScalelEl = document.getElementsByClassName('btn-scale')[0];

  if (typeof carGalleryModalEl !== 'undefined' && carGalleryModalEl !== null) {

    let carGalleryModal = new Modal(carGalleryModalEl, {
      keyboard: false
    })

    const swiperThumbs = new Swiper(".swiper-thumbs", {
      modules: [Navigation, Mousewheel],
      slidesPerView: 4,
      direction: 'vertical',
      spaceBetween: 10,
      lazy: true,
      watchSlidesProgress: true,
      preloadImages: false,
      mousewheel: true,
      loopFillGroupWithBlank: true,
      loop: true,
      slideToClickedSlide: true,
      threshold: 20,
    });

    const swiperMain = new Swiper(".swiper-main", {
      modules: [Navigation, Thumbs],
      preloadImages: false,
      loopFillGroupWithBlank: true,
      loop: true,
      lazy: true,
      loopedSlides: 4,
      threshold: 20,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: swiperThumbs,
      },
      keyboard: {
        enabled: true,
        onlyInViewport: false,
      },
      on: {
        click: function () {
          carGalleryModal.show();
        },
      },
    });

    const swiperModal = new Swiper(".swiper-modal", {
      modules: [Navigation, Controller, Keyboard, Mousewheel, Zoom],
      slidesPerView: 1,
      direction: 'horizontal',
      spaceBetween: false,
      autoHeight: false,
      centeredSlides: true,
      preloadImages: false,
      lazy: true,
      watchSlidesProgress: true,
      observer: true,
      observeParents: true,
      loopFillGroupWithBlank: true,
      loop: true,
      loopedSlides: 4,
      threshold: 20,
      on: {
        zoomChange: function () {
          setTimeout(function(){
            if(swiperModal.zoom.scale == 1) {
              carGalleryModaScalelEl.classList.remove('active');
            } else {
              carGalleryModaScalelEl.classList.add('active');
            }
          }, 100);
        },
      },
      zoom: {
        containerClass: 'swiper-zoom-container'//'swiper-wrapper'
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      keyboard: {
        enabled: true,
        onlyInViewport: false,
      },
    });

    // swiperMain.on('slideChange', function () {
    //   var activeSlideIndex = swiperMain.realIndex; // Get the active slide index
    //   swiperThumbs.slideTo(activeSlideIndex, 500, false); // Scroll the thumbs slider to the active slide
    // });

    var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    carGalleryModalEl.addEventListener('show.bs.modal', function (event) {
      if(width < 767) {
        toggleFullScreen();
      }
      setTimeout(function(){
        if(swiperModal !== false) {
          swiperModal.update()
          swiperModal.slideTo(swiperMain.clickedIndex,1000);
          //swiperModal.lazy.load();
        }
      }, 500);
    });

    //fix modal "free mode" to "classic mode" (show full slide)
    carGalleryModalEl.addEventListener('hidden.bs.modal', function (event) {
      if(width < 767) {
        toggleFullScreen();
      }
      swiperModal.zoom.out();
      swiperMain.lazy.load();
      swiperMain.update()
    });

    //scale
    carGalleryModaScalelEl.addEventListener('click', function (event) {
      swiperModal.zoom.toggle();
    });

    // Sync between swipers.
    if (swiperMain && swiperModal) {
      swiperModal.controller.control = swiperMain
    }
  }
});

function toggleFullScreen() {
  try {
    if (!document.fullscreenElement) {
      document.body.webkitRequestFullscreen();
    } else {
      document.webkitExitFullscreen();
    }
  } catch (err) {
    //console.log(err);
  }
}
