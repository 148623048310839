<template>
  <label class="image-checkbox__item image-checkbox__item--modal" :for="fieldId">
    <div class="d-flex">
      <div class="flex-fill">
        <input @input="check"
               type="radio"
               :checked="checked"
               :id="fieldId"
               v-bind="$attrs"
               :name="fieldId"
               class="form-check-input image-checkbox__radio">
      </div>
      <div class="flex-fill text-end">
        <i class="icon icon--md icon-question-circle"
           data-bs-toggle="tooltip"
           role="tooltip"
           aria-label="tooltip text"
           data-bs-placement="bottom"
           title=""
           data-bs-original-title="Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et. Sunt qui esse pariatur duis deserunt mollit dolore cillum minim.">
        </i>
      </div>
    </div>
    <div class="image-checkbox__image">
      <SvgIcon :path="`car-bodies/${option.iconName}`" alt="Osobní" :width="162" :height="50" />
    </div>
    <div class="text-center">
      <div class="text-gray-700 small">{{ option.value }}</div>
    </div>
  </label>
</template>

<script>
import { computed } from "vue";
import SvgIcon from './general/BaseSvgIcon.vue'

export default {
  name: 'FormImageOption',
  emits: ["toggled"],
  components: {
    SvgIcon
  },
  props: {
    option: {
      type: Object,
      required: true,
    },
    fieldId: {
      type: String,
      default: () => `radio-image-${Math.random().toString(36).slice(7)}`,
    },
    selected: {
      type: Object,
      required: true,
    }
  },
  setup(props, context) {
    const checked = computed(() => props.selected && props.selected.value === props.option.value)
    const check = () => {
      context.emit("toggled", props.option);
    }

    return {
      checked,
      check,
    };
  }
}
</script>