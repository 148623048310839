<template>
  <section class="section" id="step-2">
    <CarEquipmentList :car-equipments="this.carEquipments" />

    <h3 class="mt-5">Podrobnější popis</h3>
    <div class="row">
      <FormInput
        v-model="subtitle"
        label="Podtitul inzerátu"
        :show-length-counter="true"
        :max-length="35"
        css-style="col-12 pt-lg-2 pt-2 pb-lg-3 pb-2"
        sub-label="Objeví se hned pod názvem inzerátu “Značka | Model”. Charakterizujte vozidlo."
      />
      <FormTextArea
        v-model="advertisementText"
        label="Text inzerátu"
        css-style="col-12 pt-lg-2 pt-2 pb-lg-3 pb-2"
        :show-length-counter="true"
        :max-length="2000"
        placeholder="Uveďte informace o technickém stavu, servisní historii a další informace..."
        sub-label="Podrobnější informace o vozidle."
        :rows-count="3"
      />
    </div>

    <h3 class="mt-5">Fotografie</h3>
    <FormFile
      label="Fotografie"
      modal-id="upload-images"
      :is-required="true"
      :images="images"
    />

    <h3 class="mt-5">Cena</h3>
    <div class="row gx-4">
      <FormInput
        v-model="price"
        label="Požadovaná cena (Kč)"
        input-type="text"
        input-mode="numeric"
        masked-type="number"
        :pattern="/[0-9]*/"
        :is-required="true"
        css-style="col-xl-4 col-lg-6 pt-lg-2 pt-2 pb-lg-3 pb-2"
      />
      <FormBoolean
        v-model="vatDeduction"
        label="Odpočet DPH"
        id="vat-deduction"
        :is-required="true"
      />
    </div>
  </section>
</template>

<script>
import ApiService from '../../../services/apiService.js';
import FormInput from '../../FormInput.vue';
import FormTextArea from '../../FormTextArea.vue';
import FormBoolean from '../../FormBoolean.vue';
import CarEquipmentList from '../../advertisement/CarEquipmentList.vue';
import FormFile from '../../FormFile.vue';

export default {
  name: 'MoreInfoStep',

  components: {
    FormFile,
    FormInput,
    FormTextArea,
    FormBoolean,
    CarEquipmentList,
  },

  data() {
    return {
      carEquipments: [],
    };
  },

  computed: {
    subtitle: {
      get() {
        return this.$store.state.AdvertisementStore.advertisement.subtitle;
      },
      set(value) {
        this.$store.commit('AdvertisementStore/subtitle', value);
      },
    },

    advertisementText: {
      get() {
        return this.$store.state.AdvertisementStore.advertisement
          .advertisementText;
      },
      set(value) {
        this.$store.commit('AdvertisementStore/advertisementText', value);
      },
    },

    price: {
      get() {
        return this.$store.state.AdvertisementStore.advertisement.price;
      },
      set(value) {
        this.$store.commit('AdvertisementStore/price', value);
      },
    },

    vatDeduction: {
      get() {
        return this.$store.state.AdvertisementStore.advertisement.vatDeduction;
      },
      set(value) {
        this.$store.commit('AdvertisementStore/vatDeduction', value);
      },
    },

    images() {
      return this.$store.state.AdvertisementStore.advertisement.images;
    },
  },

  methods: {
    openModal(modalId) {
      this.$store.dispatch('ModalStore/openModal', {
        id: modalId,
      });
    },
  },

  async mounted() {
    const { data } = await ApiService.get('car_equipments')
    this.carEquipments = data.carEquipments
  },
};
</script>
