export const ShareModal = {
  init() {
    const fbAppId = 291494419107518; //FB app - facebook developers (must be set for sending to messenger)
    const shareModal = document.querySelector('#shareModal');
    const shareInput = document.querySelector('#shareInput');
    const shareCopyButton = document.querySelector('#shareCopyButton');

    const facebookIcon = document.querySelector('.share-icon--facebook');
    const messengerIcon = document.querySelector('.share-icon--messenger');
    const twitterIcon = document.querySelector('.share-icon--twitter');
    const whatsappIcon = document.querySelector('.share-icon--whatsapp');
    const mailIcon = document.querySelector('.share-icon--mail');

    shareModal.addEventListener('show.bs.modal', (event) => {
      const button = event.relatedTarget;
      let url = button.dataset.url;

      if (!url) {
        url = window.location.href;
      }

      shareInput.value = url;
      facebookIcon.href = 'https://www.facebook.com/sharer.php?u=' + url;
      messengerIcon.href =
        'https://www.facebook.com/dialog/send?app_id=' +
        fbAppId +
        '&redirect_uri=' +
        url +
        '&link=' +
        url;
      twitterIcon.href = 'https://twitter.com/intent/tweet?url=' + url;
      whatsappIcon.href = 'https://api.whatsapp.com/send?text=' + url;
      mailIcon.href = 'mailto:?subject=Odkaz z webu prodamauto.cz&body=' + url;
    });

    shareCopyButton.onclick = () => {
      shareInput.select();
      if (document.execCommand('copy')) {
        shareCopyButton.innerText = 'Zkopírováno!';
        setTimeout(() => {
          shareCopyButton.innerText = 'Kopírovat';
        }, 3500);
      }
    };
  },
};
