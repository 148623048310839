import { Collapse } from 'bootstrap';

export const AcorrdionService = {
  init() {
    const accordion = document.querySelector('.accordion');
    if (typeof accordion !== 'undefined' && accordion !== null) {
      let activeItem = accordion.dataset.activeItem;
      if (location.hash !== null && location.hash !== '') {
        new Collapse(location.hash, {
          toggle: true,
        });
      } else {
        if (activeItem) {
          new Collapse('#' + activeItem, {
            toggle: true,
          });
        }
      }
    }
  },
};
