<template>
  <div :class="{ 'bg-info-lighter': !isValid, [cssStyle]: true }">
    <label :for="id" class="form-label"
      >{{ label }}<span v-show="isRequired" class="form-label__required"
    /></label>
    <div
      v-if="subLabel.length > 0"
      class="form-label__info-text"
      v-html="subLabel"
    />
    <div class="form-control-wrap form-control-wrap--lg">
      <select
        :id="id"
        :disabled="isDisabled"
        :readonly="isReadOnly"
        class="form-select form-select-lg"
        @change="$emit('update:modelValue', $event.target.value)"
      >
        <option value="">
          {{ placeholder }}
        </option>
        <option
          v-for="option in options"
          :key="option.id"
          :value="option.value"
          :selected="modelValue === option.value"
        >
          {{ option.value }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormSelectSimple',

  props: {
    options: {
      type: Array,
      required: true,
    },

    modelValue: {
      type: String,
      default: undefined,
    },

    id: {
      type: String,
      required: true,
    },

    label: {
      type: String,
      required: true,
    },

    subLabel: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: 'Uveďte',
    },

    isRequired: {
      type: Boolean,
      default: false,
    },

    cssStyle: {
      type: String,
      default: '',
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isReadOnly: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isValid() {
      return (
        this.modelValue !== undefined &&
        this.modelValue !== '' &&
        this.modelValue !== null
      );
    },
  },
};
</script>
