<template>
  <div class="col-xl-4 col-lg-6 pt-lg-2 pt-2 pb-lg-3 pb-2" :class="{ 'bg-info-lighter': !selectedOption }">
    <label :for="id" class="form-label">
      {{ label }}
      <span v-if="isRequired" class="form-label__required"></span>
    </label>
    <div class="pt-2">
      <div class="form-check form-check-inline">
        <input :checked="selectedOption && modelValue" @change="$emit('update:modelValue', true)" class="form-check-input" type="radio" :name="id" :id="`${id}-1`">
        <label class="form-check-label" :for="`${id}-1`">{{ yesLabel }}</label>
      </div>
      <div class="form-check form-check-inline">
        <input :checked="selectedOption && !modelValue" @change="$emit('update:modelValue', false)" class="form-check-input" type="radio" :name="id" :id="`${id}-2`">
        <label class="form-check-label" :for="`${id}-2`">{{ noLabel }}</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormBoolean',

  props: {
    modelValue: {
      type: Boolean,
      default: undefined
    },
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    yesLabel: {
      type: String,
      default: 'Ano'
    },
    noLabel: {
      type: String,
      default: 'Ne'
    },
    isRequired: {
      type: Boolean,
      default: false
    },
  },

  computed: {
    selectedOption() {
      return this.modelValue !== undefined
    }
  },
}
</script>