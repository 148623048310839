document.addEventListener("DOMContentLoaded", function(event) { 

    const formCounters = document.querySelectorAll('.form-counter')

    if (typeof formCounters !== 'undefined' && formCounters !== null) {
        formCounters.forEach(function (formCounter) {
            formElemId = formCounter.getAttribute('data-counter');
            formElem = document.getElementById(formElemId);

            formElem.addEventListener('input', function (e) {
                counter(formCounter, e.target);
            });
            counter(formCounter, formElem);
        })
    }

})

function counter(formCounter, formElem) {
    const maxLength = formElem.getAttribute('maxlength');
    const currentLength = formElem.value.length;
    formCounter.innerHTML = `${currentLength}/${maxLength}`;
}