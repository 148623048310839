//documentation: https://github.com/uNmAnNeR/imaskjs
import IMask from 'imask';

document.addEventListener('DOMContentLoaded', function (event) {
  let phoneInputs = document.querySelectorAll('.js-mask-phone');
  let priceInputs = document.querySelectorAll('.js-mask-price');

  //phones
  if (typeof phoneInputs !== 'undefined' && phoneInputs !== null) {
    phoneInputs.forEach(function (phoneInput) {
      IMask(phoneInput, {
        mask: '000 000 000',
      });
    });
  }

  //prices
  if (typeof priceInputs !== 'undefined' && priceInputs !== null) {
    priceInputs.forEach(function (priceInput) {
      IMask(priceInput, {
        mask: Number,
        thousandsSeparator: ' ',
      });
    });
  }
});
