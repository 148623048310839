<template>
  <div id="advertisement-stepper">
    <component :is="stepToRender" />
  </div>
</template>

<script>
import { computed, nextTick, reactive, watch } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'CheckoutWizard',

  emits: ['continueClicked'],

  props: {
    steps: {
      type: Array,
      required: true,
    },
    action: {
      type: String,
      default: 'create'
    }
  },

  setup(props) {
    const store = useStore()

    const steps = reactive(props.steps)
    const currentStep = computed(() => store.state.AdvertisementStore.currentStep)
    const currentStepValues = computed(() => steps[currentStep.value])
    const stepToRender = computed(() => currentStepValues.value.component)

    watch(currentStep, () => {
      nextTick(() => {
        window.scroll({
          top: props.action === 'create' ? 550 : 0,
          left: 0,
          behavior: 'smooth',
        })
      })
    })

    return {
      stepToRender,
      currentStep,
    }
  },
}
</script>
