<template>
  <BaseModal :modal-id="modalId" :title="title" :css-classes="cssClasses" :showFooter="false">
    <div class="mb-2 mt-2 mb-md-3 mt-md-4">
      <div v-if="options" class="image-checkbox">
        <FormImageOption v-for="option in options"
                         :key="option"
                         @toggled="toggled"
                         :selected="selectedOption"
                         :option="option"/>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from "./BaseModal.vue";
import FormImageOption from "../FormImageOption.vue";

export default {
  name: 'SingleImageSelectionModal',

  emits: ["selected"],

  components: {
    BaseModal,
    FormImageOption
  },

  props: {
    options: {
      type: Array,
      required: true
    },

    title: {
      type: String,
      default: '',
    },

    modalId: {
      type: String,
      default: () => `modal-id-${Math.random().toString(36).slice(7)}`,
    },

    cssClasses: {
      type: Array,
      default: () => []
    },
  },

  data() {
    return {
      selectedOption: {},
    }
  },

  methods: {
    toggled(value) {
      this.selectedOption = value
      this.$emit('selected', this.selectedOption)
      this.$store.dispatch('ModalStore/closeModal')
    },
  },
}
</script>