<template>
  <Teleport to="#modals">
    <div class="modal fade" :class="{ show: isOpened }" :id="modalId" tabindex="-1" :style="`display: ${isOpened ? 'block' : 'none'}`" aria-labelledby="carBrandsLabel" aria-modal="true" role="dialog">
      <div class="modal-dialog modal-dialog-scrollable box-shadow-5" :class="cssClasses">
        <div class="modal-content">
          <div v-if="showHeader" class="modal-header">
            <h3 class="modal-title h3" id="carBrandsLabel">{{ title }}</h3>
            <button @click="closeModal" type="button" class="btn-close" aria-label="Close">
              <i class="icon icon--lg icon-dismiss d-none d-md-block d-lg-block d-xl-block d-xxl-block"></i>
              <i class="icon icon--md icon-dismiss d-md-none"></i>
            </button>
          </div>
          <div class="modal-body">
            <slot/>
          </div>
          <div v-if="showFooter" class="modal-footer d-flex">
            <slot name="ok-button"></slot>
            <button @click="closeModal" type="button" class="btn btn-lg btn-outline-gray flex-fill flex-sm-grow-0">Storno</button>
          </div>
          <slot v-else name="footer"></slot>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
import { Modal } from 'bootstrap';

export default {
  name: 'BaseModal',
  props: {
    title: {
      type: String,
      default: '',
    },

    modalId: {
      type: String,
      default: () => `modal-id-${Math.random().toString(36).slice(7)}`,
    },

    cssClasses: {
      type: Array,
      default: () => []
    },

    showHeader: {
      type: Boolean,
      default: true
    },

    showFooter: {
      type: Boolean,
      default: true
    },
  },

  // watch: {
  //   isOpened(value) {
  //     let modal = new Modal(document.getElementById(this.modalId))
  //     console.log(modal)
  //     if(value) {
  //       modal.show()
  //     } else {
  //       modal.hide()
  //     }
  //   }
  // },

  computed: {
    isOpened() {
      return this.$store.state.ModalStore.openedModalId === this.modalId
    },

    modalTitle() {
      return this.title || this.$store.getters['ModalStore/params'].title
    },
  },

  methods: {
    closeModal() {
      this.$store.dispatch('ModalStore/closeModal')
    },
  },
}
</script>