<template>
  <BaseModal :modal-id="modalId" :css-classes="cssClasses" :title="title">
    <div class="mb-2 mt-2 mb-md-3 mt-md-4">
      <div v-if="options" class="image-checkbox">
        <FormImageCheckbox v-for="option in options" :key="option.id" @toggled="toggled" :fieldId="option.id" :selected="selectedOptions.some(o => o.id === option.id)" :option="option"/>
      </div>
    </div>

    <template v-slot:ok-button>
      <button @click="save" type="button" class="btn btn-lg btn-gray flex-fill flex-sm-grow-0">Uložit</button>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../modals/BaseModal.vue";
import FormImageCheckbox from "../FormImageCheckbox.vue";

export default {
  name: 'MultiImageSelectionModal',

  components: {
    FormImageCheckbox,
    BaseModal
  },

  emits: ['selected'],

  props: {
    options: {
      type: Array,
      required: true
    },

    savedOptions: {
      type: Array,
      default: () => []
    },

    title: {
      type: String,
      default: '',
    },

    modalId: {
      type: String,
      default: () => `modal-id-${Math.random().toString(36).slice(7)}`,
    },

    cssClasses: {
      type: Array,
      default: () => []
    },

    optionWrapperStyle: {
      type: String,
      default: "col-12 col-sm-4 col-md-4 col-lg-3 mb-1"
    },
  },

  data() {
    return {
      selectedOptions: []
    }
  },

  computed: {
    openModalId() {
      return this.$store.state.ModalStore.openedModalId
    },
  },

  watch: {
    openModalId(value) {
      if(value === this.modalId) {
        this.selectedOptions = [...this.savedOptions]
      }
    }
  },

  methods: {
    toggled(option) {
      const index = this.selectedOptions.findIndex(o => {
        return o.id === option.id
      })
      if (index >= 0) {
        this.selectedOptions.splice(index, 1);
      } else {
        this.selectedOptions.push(option);
      }
    },

    save() {
      this.$emit('selected', this.selectedOptions)
      this.$store.dispatch('ModalStore/closeModal')
    }
  },
}
</script>