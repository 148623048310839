//checkbox
document.addEventListener("DOMContentLoaded", function(event) {
    let imageCheckboxElements = document.getElementsByClassName("image-checkbox__checkbox");

    for (let i = 0; i < imageCheckboxElements.length; i++) {
        if (imageCheckboxElements[i].checked) {
            imageCheckboxElements[i].closest(".image-checkbox__item").classList.add("image-checkbox__item--active");
        } else {
            imageCheckboxElements[i].closest(".image-checkbox__item").classList.remove("image-checkbox__item--active");
        }

        imageCheckboxElements[i].addEventListener('change', (event) => {
            if (event.currentTarget.checked) {
                event.currentTarget.closest(".image-checkbox__item").classList.add("image-checkbox__item--active");
            } else {
                event.currentTarget.closest(".image-checkbox__item").classList.remove("image-checkbox__item--active");
            }
        })
    }
})

//radio
document.addEventListener("DOMContentLoaded", function(event) {
    let imageRadioElements = document.querySelectorAll('.image-checkbox__radio');
    
    imageRadioElements.forEach(function (imageRadioElement) {
    imageRadioElement.addEventListener('change', function(e) {
        imageRadioElements.forEach(el => el.closest(".image-checkbox__item").classList.remove('image-checkbox__item--active'))
        if(imageRadioElement.checked) {
            imageRadioElement.closest(".image-checkbox__item").classList.add('image-checkbox__item--active')
        }
    });

        if(imageRadioElement.checked) {
            imageRadioElement.closest(".image-checkbox__item").classList.add('image-checkbox__item--active')
        }
    })

})


