<template>
  <div>
    <div class="bg-white rounded-4 box-shadow-1 p-2 p-md-4 pt-md-4 pt-3">
      <h2>
        {{ this.action === 'create' ? 'Zadejte inzerát' : 'Editace inzerátu' }}
        ve 3 krocích
      </h2>
      <Stepper
        :steps="stepNames"
        :current-step="currentStep"
        @update-current-step="updateCurrentStep"
      />

      <div
        class="p-3 mb-4 mt-0"
        style="
          background: linear-gradient(
            211.85deg,
            #ffe4e6 26.23%,
            #fedadc 83.38%
          );
          border-radius: 15px;
          color: #000;
          border: 1px solid #f3646c;
        "
        v-if="errors.length"
      >
        <h4 class="mb-2">Prosím opravte chyby:</h4>
        <ul>
          <li v-for="error in errors" style="color: #000">{{ error }}</li>
        </ul>
      </div>

      <Wizard :steps="steps" :action="this.action" />

      <div v-if="currentStep < steps.length - 1">
        <p class="small mt-6">
          <span class="text-primary">*</span> Před pokračováním vyplňte všechny
          povinná pole.
        </p>
        <div class="d-flex gap-2 flex-column flex-md-row mt-6">
          <button
            v-if="currentStep > 0"
            class="btn btn-lg btn-outline-gray mr-auto"
            @click.prevent="updateCurrentStep(currentStep - 1)"
            :disabled="isLoading"
          >
            Zpět
          </button>
          <button
            class="btn btn-lg btn-gray"
            @click.prevent="updateCurrentStep(currentStep + 1)"
            :disabled="isLoading"
          >
            Pokračovat
          </button>
        </div>
      </div>

      <div
        v-if="currentStep === steps.length - 1"
        class="d-flex gap-2 flex-column flex-md-row mt-6"
      >
        <button
          class="btn btn-lg btn-outline-gray mr-auto"
          @click.prevent="updateCurrentStep(currentStep - 1)"
          :disabled="isLoading"
        >
          Zpět
        </button>
        <button
          v-if="action === 'create' || action === 'update' && !advertisement?.isPublished"
          @click="beforeCreateAdvertisement('draft')"
          class="btn btn-lg btn-outline-gray"
          :disabled="isLoading"
        >
          Zobrazit náhled
        </button>
        <button
          @click="beforeCreateAdvertisement('published')"
          class="btn btn-lg btn-gray"
          :disabled="isLoading"
        >
          Publikovat inzerát
        </button>
      </div>
    </div>
  </div>

  <SingleSelectionModal
    modal-id="single-brand-modal"
    :options="brands"
    title="Vyberte značku"
    :cssClasses="['modal-xl']"
    @selected="selectBrand"
  />
  <SingleSelectionModal
    modal-id="single-model-modal"
    :options="models"
    title="Vyberte model"
    :cssClasses="['modal-xl']"
    @selected="selectModel"
  />
  <SingleImageSelectionModal
    modal-id="single-body-modal"
    :options="bodies"
    title="Vyberte karosérii"
    :cssClasses="['modal-xl']"
    @selected="selectBody"
  />
  <SingleSelectionModal
    modal-id="single-seats-count-modal"
    :options="seatsCounts"
    title="Vyberte počet sedadel"
    :cssClasses="['modal-xsm']"
    @selected="selectSeatsCount"
    option-wrapper-style="col-12 mb-1"
  />
  <SingleSelectionModal
    modal-id="single-gearbox-modal"
    :options="gearboxes"
    title="Vyberte převodovku"
    :cssClasses="['modal-xl']"
    @selected="selectGearbox"
  />
  <SingleSelectionModal
    modal-id="single-wheel-drive-modal"
    :options="wheelDrives"
    title="Vyberte pohon"
    :cssClasses="['modal-xl']"
    @selected="selectWheelDrive"
  />
  <SingleSelectionModal
    modal-id="single-fuel-modal"
    :options="fuels"
    title="Vyberte palivo"
    :cssClasses="['modal-xl']"
    @selected="selectFuel"
  />
  <SingleScrollSelectionModal
    modal-id="single-displacement-modal"
    :options="displacements"
    title="Vyberte objem"
    :cssClasses="['modal-xs']"
    name="displacement-radio"
    unit="ccm"
    @selected="selectDisplacement"
  />
  <SingleScrollSelectionModal
    modal-id="single-engine-power-modal"
    :options="enginePowers"
    title="Vyberte výkon"
    :cssClasses="['modal-xs']"
    name="engine-power-radio"
    unit="kW"
    @selected="selectEnginePower"
  />
  <SingleSelectionModal
    modal-id="single-car-state-modal"
    :options="carStates"
    title="Vyberte stav vozu"
    :cssClasses="['modal-xl']"
    @selected="selectCarState"
  />
  <SingleScrollSelectionModal
    modal-id="single-rides-since-year-modal"
    :options="ridesSinceYears"
    title="Vyberte rok"
    :cssClasses="['modal-sm']"
    :formatted="false"
    name="rides-since-year-radio"
    @selected="selectRidesSinceYear"
  />
  <SingleSelectionModal
    modal-id="single-country-origin-modal"
    :options="countries"
    title="Vyberte zemi"
    :cssClasses="['modal-sm']"
    @selected="selectCountryOrigin"
  />
  <UploadImagesModal modal-id="upload-images" :cssClasses="['modal-xxl']" />
  <ModalAlert />
  <ModalAdvertisementCreated />
</template>

<script>
import ApiService from '../../services/apiService.js';
import HelperService from '../../services/helperService.js';
import { range, isEmpty } from 'lodash';
import { computed, markRaw, onBeforeMount, ref } from 'vue';
import FormSelect from '../FormSelect.vue';
import SingleSelectionModal from '../modals/SingleSelectionModal.vue';
import SingleImageSelectionModal from '../modals/SingleImageSelectionModal.vue';
import FormBoolean from '../FormBoolean.vue';
import FormInput from '../FormInput.vue';
import FormTextArea from '../FormTextArea.vue';
import CarEquipmentList from './CarEquipmentList.vue';
import SingleScrollSelectionModal from '../modals/SingleScrollSelectionModal.vue';
import UploadImagesModal from '../modals/UploadImagesModal.vue';
import Stepper from './wizard/Stepper.vue';

import Wizard from './wizard/index.vue';
import BaseInfoStep from './steps/BaseInfoStep.vue';
import MoreInfoStep from './steps/MoreInfoStep.vue';
import ContactInfoStep from './steps/ContactInfoStep.vue';
import { useStore } from 'vuex';
import ModalAlert from '../modals/ModalAlert.vue';
import ModalAdvertisementCreated from '../modals/ModalAdvertisementCreated.vue';

export default {
  name: 'AdvertisementForm',

  components: {
    ModalAdvertisementCreated,
    ModalAlert,
    UploadImagesModal,
    CarEquipmentList,
    FormTextArea,
    FormInput,
    FormBoolean,
    SingleSelectionModal,
    SingleImageSelectionModal,
    SingleScrollSelectionModal,
    FormSelect,
    Wizard,
    Stepper,
  },

  setup() {
    const store = useStore();
    const currentStep = computed(
      () => store.state.AdvertisementStore.currentStep
    );
    const steps = [
      {
        stepperProps: { title: '1. Základní informace' },
        component: markRaw(BaseInfoStep),
      },
      {
        stepperProps: { title: '2. Rozšířené informace' },
        component: markRaw(MoreInfoStep),
      },
      {
        stepperProps: { title: '3. Kontaktní údaje' },
        component: markRaw(ContactInfoStep),
      },
    ];
    const stepNames = steps.map((item) => item.stepperProps.title);

    onBeforeMount(() => {
      store.commit('AdvertisementStore/updateCurrentStep', 0); // Reset wizard on refresh / load
    });

    return {
      steps,
      stepNames,
      currentStep,
    };
  },

  props: {
    advertisementId: {
      type: Number,
      default: undefined,
    },
    action: {
      type: String,
      default: 'create',
    },
  },

  data() {
    return {
      advertisement: undefined,
      errors: [],
      isLoading: false,
      brands: [],
      models: [],
      bodies: [],
      seatsCounts: [
        { id: 1, value: '1-2' },
        { id: 2, value: '3' },
        { id: 3, value: '4-5' },
        { id: 4, value: '6+' },
      ],
      gearboxes: [],
      wheelDrives: [],
      fuels: [],
      displacements: HelperService.objectMapper(range(0, 8000, 100)),
      enginePowers: HelperService.objectMapper(range(30, 500, 1)),
      carStates: [
        //TODO for KUBA - Maybe you will update the model - I added new values here:
        { id: 1, value: 'Nové' },
        { id: 2, value: 'Roční' },
        { id: 3, value: 'Ojeté' },
        { id: 4, value: 'Havarované/Porouchané' },
      ],
      ridesSinceYears: HelperService.objectMapper(
        range(1970, new Date().getFullYear() + 1, 1).reverse()
      ),
      countries: HelperService.objectMapper([
        'Česká republika',
        'Slovenská republika',
        'Německo',
        'Rakousko',
        'Švýcarsko',
        'Francie',
        'Itálie',
        'Holandsko',
        'Belgie',
        'Španělsko',
        'Dánsko',
        'USA',
        'Jiná',
      ]),
    };
  },

  watch: {
    async openModalId(value) {
      switch (value) {
        case 'single-brand-modal':
          const brands = await this.loadResource(
            `brands?model_type=${this.modelType}`
          );
          this.brands = brands.map((i) => {
            return { id: i.id, value: i.name, label: i.name, count: i.count };
          });
          break;
        case 'single-model-modal':
          const models = await this.loadResource(
            `models?brand_id=${this.selectedBrand.id}&model_type=${this.modelType}`
          );
          this.models = models.map((i) => {
            return { id: i.id, value: i.name, label: i.name, count: i.count };
          });
          break;
        case 'single-body-modal':
          const bodies = await this.loadResource(
            `bodies?model_type=${this.modelType}`
          );
          this.bodies = bodies.map((i) => {
            return {
              id: i.id,
              value: i.name,
              label: i.name,
              count: i.count,
              iconName: i.iconName,
            };
          });
          break;
        case 'single-gearbox-modal':
          const gearboxes = await this.loadResource('gearboxes');
          this.gearboxes = gearboxes.map((i) => {
            return { id: i.id, value: i.name, label: i.name, count: i.count };
          });
          break;
        case 'single-wheel-drive-modal':
          const wheelDrives = await this.loadResource('wheel_drives');
          this.wheelDrives = wheelDrives.map((i) => {
            return { id: i.id, value: i.name, label: i.name, count: i.count };
          });
          break;
        case 'single-fuel-modal':
          const fuels = await this.loadResource('fuels');
          this.fuels = fuels.map((i) => {
            return { id: i.id, value: i.name, label: i.name, count: i.count };
          });
          break;
      }
    },

    modelType() {
      this.$store.commit('AdvertisementStore/selectBrand', undefined);
      this.$store.commit('AdvertisementStore/selectModel', undefined);
    },
  },

  computed: {
    modelType() {
      return this.$store.state.AdvertisementStore.advertisement.modelType;
    },
    openModalId() {
      return this.$store.state.ModalStore.openedModalId;
    },
    selectedBrand() {
      return this.$store.state.AdvertisementStore.advertisement.brand;
    },
    selectedModel() {
      return this.$store.state.AdvertisementStore.advertisement.model;
    },
    selectedBody() {
      return this.$store.state.AdvertisementStore.advertisement.body;
    },
    selectedSeatsCount() {
      return this.$store.state.AdvertisementStore.advertisement.seatsCount;
    },
    selectedGearbox() {
      return this.$store.state.AdvertisementStore.advertisement.gearbox;
    },
    selectedWheelDrive() {
      return this.$store.state.AdvertisementStore.advertisement.wheelDrive;
    },
    selectedFuel() {
      return this.$store.state.AdvertisementStore.advertisement.fuel;
    },
    selectedDisplacement() {
      return this.$store.state.AdvertisementStore.advertisement.displacement;
    },
    selectedEnginePower() {
      return this.$store.state.AdvertisementStore.advertisement.enginePower;
    },
    selectedCarState() {
      return this.$store.state.AdvertisementStore.advertisement.carState;
    },
    selectedRidesSinceYear() {
      return this.$store.state.AdvertisementStore.advertisement.ridesSinceYear;
    },
    selectedCountryOrigin() {
      return this.$store.state.AdvertisementStore.advertisement.countryOrigin;
    },
    selectedFirstOwner() {
      return this.$store.state.AdvertisementStore.advertisement.firstOwner;
    },
    filledMileage() {
      return this.$store.state.AdvertisementStore.advertisement.mileage;
    },
    filledVin() {
      return this.$store.state.AdvertisementStore.advertisement.vin;
    },
    filledSubtitle() {
      return this.$store.state.AdvertisementStore.advertisement.subtitle;
    },
    filledPrice() {
      return this.$store.state.AdvertisementStore.advertisement.price;
    },
    selectedVatDeduction() {
      return this.$store.state.AdvertisementStore.advertisement.vatDeduction;
    },
    advertisementText() {
      return this.$store.state.AdvertisementStore.advertisement
        .advertisementText;
    },
    carEquipments() {
      return this.$store.state.AdvertisementStore.advertisement.carEquipments;
    },
    customEquipments() {
      return this.$store.state.AdvertisementStore.advertisement
        .customEquipments;
    },
    authorName() {
      return this.$store.state.AdvertisementStore.advertisement.authorName;
    },
    contactEmail() {
      return this.$store.state.AdvertisementStore.advertisement.contactEmail;
    },
    contactPhone() {
      return this.$store.state.AdvertisementStore.advertisement.contactPhone;
    },
    region() {
      return this.$store.state.AdvertisementStore.advertisement.region;
    },
    authorDescription() {
      return this.$store.state.AdvertisementStore.advertisement
        .authorDescription;
    },
    images() {
      return this.$store.state.AdvertisementStore.advertisement.images;
    },
    modelInputDisabled() {
      return isEmpty(this.selectedBrand);
    },
  },

  methods: {
    beforeCreateAdvertisement(state) {
      if (!this.checkForm(3)) {
        return;
      }
      // When form action is create, send POST ...
      if (this.action === 'create') {
        this.createAdvertisement(state);
        // ... or send PUT as update
      } else if (this.action === 'update') {
        this.updateAdvertisement(state);
      }
    },

    buildAdvertisementPayload(state) {
      return {
        model_type: this.modelType,
        brand_id: this.selectedBrand.id,
        model_id: this.selectedModel.id,
        sub_title: this.filledSubtitle,
        displacement: this.selectedDisplacement.value,
        power: this.selectedEnginePower.value,
        price: parseFloat(this.filledPrice),
        mileage: parseFloat(this.filledMileage),
        fuel_id: this.selectedFuel.id,
        gearbox_id: this.selectedGearbox.id,
        wheel_drive_id: this.selectedWheelDrive.id,
        body_id: this.selectedBody.id,
        country_origin_id: this.selectedCountryOrigin.id,
        rides_since_year: this.selectedRidesSinceYear.value,
        seats_count_id: this.selectedSeatsCount.id,
        vin: this.filledVin,
        advertisement_text: this.advertisementText,
        car_equipments: this.carEquipments,
        custom_equipments: this.customEquipments,
        is_reserved: false,
        vat_deduction: this.selectedVatDeduction,
        first_owner: this.selectedFirstOwner,
        car_state_id: this.selectedCarState.id,
        images: this.images.map((obj, index) => ({...obj, position: index + 1})), // Adds index as position
        state: 'active',
        is_published: state === 'published',
      };
    },

    buildUserPayload() {
      return {
        name: this.authorName,
        email: this.contactEmail,
        phone: this.contactPhone,
        region: this.region,
        description: this.authorDescription,
      };
    },

    async createAdvertisement(state) {
      this.isLoading = true;
      const payload = this.buildAdvertisementPayload(state);
      const userPayload = this.buildUserPayload();
      try {
        const { data } = await ApiService.post('advertisements', {
          advertisement: payload,
          user: userPayload,
        });

        //draft? redirect to advertisement
        if (state === 'draft') {
          window.location.href = data.redirectTo;
        } else {
          this.$store.dispatch('ModalStore/openModal', {
            id: 'modal-advertisement-created',
            advertisementId: data.advertisementId,
            advertisementType: data.advertisementType,
            created: true,
            advertisementUrl: data.redirectTo,
            advertisementsIndexUrl: data.advertisementsIndexUrl,
            title: data.title,
            msg: data.msg,
          });
          this.$store.commit('AdvertisementStore/reset');
        }

      } catch (error) {
        this.$store.dispatch('ModalStore/openModal', {
          id: 'modal-alert',
          message: error.response.data.msg,
        });
      }

      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    },

    async updateAdvertisement(state) {
      this.isLoading = true;
      const payload = this.buildAdvertisementPayload(state);
      const userPayload = this.buildUserPayload();
      try {
        const { data } = await ApiService.put(
          `advertisements/${this.advertisementId}`,
          {
            advertisement: payload,
            user: userPayload,
          }
        );
        this.$store.dispatch('ModalStore/openModal', {
          id: 'modal-advertisement-created',
          advertisementUrl: data.redirectTo,
          advertisementsIndexUrl: data.advertisementsIndexUrl,
          title: data.title,
          msg: data.msg,
        });
      } catch (error) {
        this.$store.dispatch('ModalStore/openModal', {
          id: 'modal-alert',
          message: error.response.data.msg,
        });
      }

      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    },

    checkForm(stepIndex) {
      this.errors = [];

      switch (stepIndex) {
        case 1:
          if (isEmpty(this.selectedBrand))
            this.errors.push('Prosím vyberte značku');
          if (isEmpty(this.selectedModel))
            this.errors.push('Prosím vyberte model');
          if (isEmpty(this.selectedBody))
            this.errors.push('Prosím vyberte karosérii');
          if (isEmpty(this.selectedSeatsCount))
            this.errors.push('Prosím vyberte počet sedadel');
          if (isEmpty(this.selectedGearbox))
            this.errors.push('Prosím vyberte převodovku');
          if (isEmpty(this.selectedWheelDrive))
            this.errors.push('Prosím vyberte pohon kol');
          if (isEmpty(this.selectedFuel))
            this.errors.push('Prosím vyberte palivo');
          if (isEmpty(this.selectedDisplacement))
            this.errors.push('Prosím vyberte objem motoru');
          if (isEmpty(this.selectedEnginePower))
            this.errors.push('Prosím vyberte výkon motoru');
          if (isEmpty(this.selectedCarState))
            this.errors.push('Prosím vyberte stav vozu');
          if (isEmpty(this.selectedRidesSinceYear))
            this.errors.push('Prosím vyberte rok uvedení do provozu');
          if (isEmpty(this.selectedCountryOrigin))
            this.errors.push('Prosím vyberte zemi původu');
          if (this.selectedFirstOwner === undefined)
            this.errors.push('Prosím vyberte, zda jste první majitel vozu');
          if (HelperService.isBlank(this.filledMileage))
            this.errors.push('Prosím vyplňte stav tachometru');
          if (HelperService.isBlank(this.filledVin))
            this.errors.push('Prosím vyplňte VIN');
          if (
            this.filledVin &&
            !/(?=.*\d|=.*[A-Z])(?=.*[A-Z])[A-Z0-9]{17}/.test(this.filledVin)
          ) {
            this.errors.push(
              'VIN musí obsahovat 17 znaků, nesmí obsahovat písmena O,I,Q. Na posledních 4 pozicích by měly být číslice.'
            );
          }
          if (this.filledMileage && this.filledMileage.length < 2) {
            this.errors.push(
              'Prosím ověřte stav tachometru, je příliš krátký.'
            );
          }
          break;
        case 2:
          if (HelperService.isBlank(this.filledSubtitle))
            this.errors.push('Prosím vyplňte podtitul inzerátu');
          if (HelperService.isBlank(this.filledPrice))
            this.errors.push('Prosím vyplňte požadovanou cenu');
          if (this.selectedVatDeduction === undefined)
            this.errors.push('Prosím vyberte odpočet DPH');
          if (isEmpty(this.images))
            this.errors.push('Prosím nahrajte alespoň jednu fotografii');
          break;
        case 3:
          if (HelperService.isBlank(this.contactPhone))
            this.errors.push('Prosím vyplňte kontaktní telefon');
          if (HelperService.isBlank(this.region))
            this.errors.push('Prosím vyberte region, kde je možné auto vidět');
          if (this.contactPhone && this.contactPhone.length !== 9) {
            this.errors.push(
              'Prosím zkontrolujte tvar telefonu. Musí obsahovat 9 číslic'
            );
          }
          break;
        default:
      }

      if (!isEmpty(this.errors)) {
        window.scroll({
          top: this.action === 'create' ? 550 : 0,
          left: 0,
          behavior: 'smooth',
        });
        return false;
      }
      return true;
    },

    updateCurrentStep(index) {
      if (!this.checkForm(index)) {
        return;
      }

      if (index >= 0 && index < this.steps.length) {
        this.$store.commit('AdvertisementStore/updateCurrentStep', index);
      }
    },

    async loadResource(resource) {
      const { data } = await ApiService.get(resource);
      return data[Object.keys(data)[0]];
    },

    selectBrand(value) {
      if (!isEmpty(this.selectedBrand) && value.id !== this.selectedBrand.id) {
        this.$store.commit('AdvertisementStore/selectModel', undefined);
      }
      this.$store.commit('AdvertisementStore/selectBrand', value);
    },
    selectModel(value) {
      this.$store.commit('AdvertisementStore/selectModel', value);
    },
    selectBody(value) {
      this.$store.commit('AdvertisementStore/selectBody', value);
    },
    selectSeatsCount(value) {
      this.$store.commit('AdvertisementStore/selectSeatsCount', value);
    },
    selectGearbox(value) {
      this.$store.commit('AdvertisementStore/selectGearbox', value);
    },
    selectWheelDrive(value) {
      this.$store.commit('AdvertisementStore/selectWheelDrive', value);
    },
    selectFuel(value) {
      this.$store.commit('AdvertisementStore/selectFuel', value);
    },
    selectDisplacement(value) {
      this.$store.commit('AdvertisementStore/selectDisplacement', value);
    },
    selectEnginePower(value) {
      this.$store.commit('AdvertisementStore/selectEnginePower', value);
    },
    selectCarState(value) {
      this.$store.commit('AdvertisementStore/selectCarState', value);
    },
    selectRidesSinceYear(value) {
      this.$store.commit('AdvertisementStore/selectRidesSinceYear', value);
    },
    selectCountryOrigin(value) {
      this.$store.commit('AdvertisementStore/selectCountryOrigin', value);
    },
    selectFirstOwner(value) {
      this.$store.commit('AdvertisementStore/selectFirstOwner', value);
    },

    async loadParams() {
      const { data } = await ApiService.get(
        `advertisements/${this.advertisementId}`
      );
      return data;
    },

    mapAdvertisementParam(param) {
      return { id: param.id, value: param.name, label: param.name };
    },
  },

  async mounted() {
    const advertisementParams = await this.loadParams();
    this.advertisement = advertisementParams
    await this.$store.commit(
      'AdvertisementStore/modelType',
      advertisementParams.modelType
    );
    this.selectBrand(this.mapAdvertisementParam(advertisementParams.brand));
    this.selectModel(this.mapAdvertisementParam(advertisementParams.model));
    this.selectBody(this.mapAdvertisementParam(advertisementParams.body));
    this.selectGearbox(this.mapAdvertisementParam(advertisementParams.gearbox));
    this.selectWheelDrive(
      this.mapAdvertisementParam(advertisementParams.wheelDrive)
    );
    this.selectFuel(this.mapAdvertisementParam(advertisementParams.fuel));
    this.selectCountryOrigin(
      this.mapAdvertisementParam(advertisementParams.countryOrigin)
    );
    this.selectSeatsCount(
      this.mapAdvertisementParam(advertisementParams.seatsCount)
    );
    this.selectDisplacement({
      value: parseInt(advertisementParams.displacement),
    });
    this.selectEnginePower({ value: parseInt(advertisementParams.power) });
    this.selectCarState({ value: advertisementParams.carState });
    this.selectRidesSinceYear({
      value: parseInt(advertisementParams.ridesSinceYear),
    });
    this.selectFirstOwner({ value: advertisementParams.firstOwner });
    this.$store.commit(
      'AdvertisementStore/mileage',
      parseInt(advertisementParams.mileage)
    );
    this.$store.commit('AdvertisementStore/vin', advertisementParams.vin);
    this.$store.commit(
      'AdvertisementStore/price',
      parseInt(advertisementParams.price)
    );
    this.$store.commit(
      'AdvertisementStore/subtitle',
      advertisementParams.subtitle
    );
    this.$store.commit(
      'AdvertisementStore/advertisementText',
      advertisementParams.advertisementText
    );
    this.$store.commit(
      'AdvertisementStore/vatDeduction',
      advertisementParams.vatDeduction
    );
    this.$store.commit(
      'AdvertisementStore/carEquipments',
      advertisementParams.customEquipments
        .filter((e) => !e.isCustom)
        .map((e) => {
          return { id: e.carEquipmentId, name: e.name, count: 0 };
        })
    );
    this.$store.commit(
      'AdvertisementStore/customEquipments',
      advertisementParams.customEquipments
        .filter((e) => e.isCustom)
        .map((e) => e.name)
    );
    this.$store.commit(
      'AdvertisementStore/customEquipments',
      advertisementParams.customEquipments
        .filter((e) => e.isCustom)
        .map((e) => e.name)
    );
    this.$store.commit('AdvertisementStore/images', advertisementParams.photos);
  },
};
</script>
